import React from "react";
import { Router } from "./router/router";
import { ThemeProvider } from "@material-ui/core/styles";

import { theme } from "./ui/theme";
import { StoreContainer, UserContainer } from "containers";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserContainer.Provider>
        <StoreContainer.Provider>
          <Router />
        </StoreContainer.Provider>
      </UserContainer.Provider>
    </ThemeProvider>
  );
};

export default App;
