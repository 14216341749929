import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { foodService } from "domain/service";
import { Food } from "domain/model/Menu";
import { DefaultTemplate } from "components/templates/DefaultTemplate";
import { StoreIdParam } from "config/menu-tool";
import { FoodCategoryForm } from "components/templates/FoodCategoryForm";

export type Props = {};

const Container: React.FC<Props> = () => {
  const [foods, setFoods] = useState<Food[] | null>(null);
  const { storeId } = useParams<StoreIdParam>();
  const storeid = Number(storeId);

  useEffect(() => {
    const fetchFoods = () => {
      foodService.fetchAllFood(storeId).then((res) => setFoods(res));
    };
    fetchFoods();
  }, [storeId]);

  return (
    <>
      <FoodCategoryForm storeId={storeid} foods={foods} />
    </>
  );
};

const FoodList: React.FC<Props> = () => {
  return (
    <DefaultTemplate title="料理一覧">
      <Container />
    </DefaultTemplate>
  );
};

export default FoodList;
