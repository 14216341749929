import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { Course } from "domain/model/Menu";
import { SnackbarContent } from "@material-ui/core";
import routes from "router/routes";

type Props = {
  isAdmin: boolean;
  storeId: string | undefined;
  courses: Course[] | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    link: {
      textDecoration: "none",
    },
    head: {
      backgroundColor: "#f1e4e0",
      fontWeight: "bold",
    },
    message: {
      margin: theme.spacing(2),
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const CourseListTemplate: React.FC<Props> = ({ isAdmin, storeId, courses }) => {
  const classes = useStyles();

  const courseDetailPath = generatePath(routes.admin.courseDetail.pathname, {
    storeId,
  });
  const history = useHistory();

  return courses?.length === 0 ? (
    <>
      <SnackbarContent
        message="データが存在しません。"
        className={classes.message}
      />
    </>
  ) : (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.head}>
                コース名
              </TableCell>
              <TableCell align="right" className={classes.head}>
                価格(円)
              </TableCell>
              <TableCell align="right" className={classes.head}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses?.map((course: Course, index: number) => (
              <StyledTableRow key={index}>
                <TableCell component="th" scope="row">
                  {course.title}
                </TableCell>
                <TableCell align="right">{course.realPrice}円</TableCell>
                <TableCell align="right">
                  <PrimaryButton
                    onClick={() =>
                      history.push(courseDetailPath, {
                        title: course.title,
                        realPrice: course.realPrice,
                        description: course.description,
                        content: course.content,
                        number: {
                          min: course.number.min,
                          max: course.number.max,
                        },
                        time: {
                          start: course.time.start,
                          end: course.time.end,
                        },
                        image: course.image,
                      })
                    }
                  >
                    編集
                  </PrimaryButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CourseListTemplate;
