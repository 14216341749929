import api from "utils/api";
import endpoint from "utils/endpoint";

export type ExternalAuth = {
  id: string;
  password: string;
};

class ExternalAuthRepository {
  async externalAuthConnect({
    vendor,
    storeId,
    id,
    password,
  }: {
    vendor: string;
  } & { storeId: number } & ExternalAuth) {
    const body = {
      external_auth: {
        vendor,
        store_id: storeId,
        auth: {
          id,
          password,
        },
      },
    };
    const url = endpoint.admin.externalAuth.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }
}

export default new ExternalAuthRepository();
