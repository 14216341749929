import React, { useState, useEffect, memo } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { MenuCreateSidebar } from "components/organisms/MenuCreateSidebar";
import MenuFormat from "components/organisms/MenuFormat";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { PrintToPdf } from "components/molecules/menu/PrintToPdf";
import { menuService } from "domain/service";

type Props = {
  storeId: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
  })
);

const MenuCreateTemplate: React.FC<Props> = memo(({ storeId }) => {
  const classes = useStyles();

  const [menusData, setMenusData] = useState<any | null>(null);

  useEffect(() => {
    const fetchMenus = () => {
      menuService
        .fetchAllMenu(storeId)
        .then((res) => setMenusData(res))
        .catch((e) => console.log(e));
    };
    fetchMenus();
  }, [storeId]);

  return (
    <div className={classes.root}>
      {/* <MenuCreateSidebar /> */}
      <PrintToPdf>
        <MenuFormat menusData={menusData} />
      </PrintToPdf>
    </div>
  );
});

export default MenuCreateTemplate;
