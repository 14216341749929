import { plainToClass } from "class-transformer";

import api from "utils/api";
import endpoint from "utils/endpoint";
import Image from "domain/model/Image";

class ImageService {
  public async fetchAllImage(
    storeId: string,
    menuType: string
  ): Promise<Image[]> {
    return await api
      .get(endpoint.admin.image.root(), {
        params: { store_id: storeId, menu_type: menuType },
      })
      .then((res) => {
        return res.data.images?.map((d: Image) =>
          plainToClass(Image, d, { excludeExtraneousValues: true })
        );
      });
  }
}

export default new ImageService();
