import React, { useState } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { TableRow, TableCell, Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { menuSynchronisationRepository } from "domain/repository";
import Loader from "components/atoms/Loader";

type Props = {
  storeId: number;
  row: any;
};

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#eee",
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
    body: {
      width: "100vw",
    },
  })
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      width: "100vw",
      height: "100vh",
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const DataSubmitForm: React.VFC<Props> = ({ row, storeId }) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [isLinkOk, setIsLinkOk] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmission = () => {
    setLoading(true);
    menuSynchronisationRepository
      .menuSynchronisation({
        vendor: row.id,
        storeId,
      })
      .then(() => {
        setLoading(false);
        setIsLinkOk(true);
        setOpen(true);
      })
      .catch((error) => {
        setIsLinkOk(false);
        setOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const LinkMessage = () => {
    if (isLinkOk === null) {
      return null;
    }
    if (isLinkOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              データを入稿しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            データ入稿に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <div>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {row.name}
        </StyledTableCell>
        <StyledTableCell>
          <PrimaryButton onClick={() => handleSubmission()}>
            最新データに更新
          </PrimaryButton>
        </StyledTableCell>
      </StyledTableRow>
      {loading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <LinkMessage />
    </div>
  );
};

export default DataSubmitForm;
