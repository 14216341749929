/**
 * [key: string]: {
 *  [key: string]: {
 *    [key: string]: Function;
 *  };
 * };
 */
const endpoint = {
  admin: {
    user: {
      signIn: () => "/api/v1/session",
      users: () => "/api/v1/users",
      update: (id: string) => `/api/m1/users/${id}`,
    },
    store: {
      root: () => "/api/v1/stores",
      show: (storeId: string) => `/api/v1/stores/${storeId}`,
      update: (storeId: string) => `/api/v1/stores/${storeId}`,
    },
    staff: {
      root: () => "/api/v1/staffs",
      show: (staffId: string) => `/api/v1/staffs/${staffId}`,
      update: (staffId: string) => `/api/v1/staffs/${staffId}`,
      delete: (staffId: string) => `/api/v1/staffs/${staffId}`,
    },
    menu: {
      root: () => "/api/v1/menu",
      show: (storeId: string) => `/api/v1/menu/${storeId}`,
    },
    image: {
      root: () => "/api/v1/images",
      delete: (id: string) => `/api/v1/images/${id}`,
    },
    externalAuth: {
      root: () => "/api/v1/external_auth",
    },
    menuSynchronisation: {
      root: () => "/api/v1/menu_synchronisation",
    },
  },

  staff: {
    user: {
      signIn: () => "/api/v1/session",
      users: () => "/api/v1/users",
    },
    store: {
      show: (storeId: string) => `/api/v1/stores/${storeId}`,
    },
    staff: {
      root: () => "/api/v1/staffs",
      show: (staffId: string) => `/api/v1/staffs/${staffId}`,
      update: (staffId: string) => `/api/v1/staffs/${staffId}`,
      delete: (staffId: string) => `/api/v1/staffs/${staffId}`,
    },
    menu: {
      root: () => "/api/v1/menu",
      show: (storeId: string) => `/api/v1/menu/${storeId}`,
    },
    image: {
      root: (staffId: string) => `/api/v1/images/${staffId}`,
      delete: (id: string) => `/api/v1/images/${id}`,
    },
    externalAuth: {
      root: (staffId: string) => `/api/v1/external_auths/${staffId}`,
    },
    menuSynchronisation: {
      root: (staffId: string) => `/api/v1/menu_synchronisation/${staffId}`,
    },
  },
} as const;

export default endpoint;
