import api from "utils/api";
import endpoint from "utils/endpoint";

type Staff = {
  name: string;
  email: string;
  password: string;
  passwordDigest: string;
};

class StaffRepository {
  async staffNew({
    storeId,
    name,
    email,
    password,
    passwordDigest,
  }: { storeId: number } & Staff) {
    const staff = {
      name,
      email,
      password,
      password_digest: passwordDigest,
    };
    const body = {
      store_id: storeId,
      staff,
    };
    const url = endpoint.admin.staff.root();

    return api.post(url, body).catch((error) => {
      if (error?.response?.data?.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  async staffUpdate(
    name: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    staffId: string
  ) {
    const url = endpoint.admin.staff.update(staffId);
    const staff = {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
    };
    const body = { staff };

    return api.put(url, body);
  }
}

export default new StaffRepository();
