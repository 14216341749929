import { Type, Expose, Transform } from "class-transformer";
import dayjs from "dayjs";

export default class StoreStaffUser {
  @Expose() id: number;
  @Expose({ name: "name" }) storeName: string;

  @Type(() => Users)
  @Expose({ name: "updated_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  updatedAt: Date;
  @Expose({ name: "created_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  createdAt: Date;
}

export class Users {
  @Expose() id: number;
  @Expose({ name: "name" }) userName: string;

  @Expose({ name: "email" }) userEmail: string;
  @Expose() role: string;
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  updatedAt: Date;
  @Expose({ name: "created_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  createdAt: Date;
}
