import React, { useState, useEffect } from "react";

import { userService } from "domain/service";
import Admin from "domain/model/User/Admin";
import AdminInfoTemplate from "components/templates/AdminInfoTemplate";
import StoreHeader from "components/organisms/StoreHeader";
import { CopyRight } from "components/molecules/CopyRight";

export type Props = {};

const AdminInfo: React.FC<Props> = () => {
  const [admin, setAdmin] = useState<Admin>();

  useEffect(() => {
    const fetchAdmin = async () => {
      userService.fetchAdminUser().then((res) => {
        setAdmin(res);
      });
    };
    fetchAdmin();
  }, []);

  return (
    <>
      <StoreHeader />
      <AdminInfoTemplate admin={admin!} />
      <CopyRight />
    </>
  );
};

export default AdminInfo;
