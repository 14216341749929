import api from "utils/api";
import endpoint from "utils/endpoint";

type Food = {
  category: string;
  name: string;
  price: string;
  description: string;
  image: string;
};

class FoodRepository {
  async foodCreate({
    storeId,
    foods,
    category,
    name,
    price,
    description,
    image,
  }: { foods: any } & { storeId: number } & Food) {
    foods.forEach((foodItem: any) => {
      if (foodItem.category === category) {
        foodItem.items.push({ name, price, description, image });
      }
    });
    const body = {
      store_id: storeId,
      menu: {
        type: "food",
        data: [...foods],
      },
    };
    const url = endpoint.admin.menu.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  async foodCategoryCreate({
    storeId,
    foodsData,
    category,
  }: { foodsData?: any } & { category: string } & { storeId: number }) {
    if (foodsData.foods !== undefined) {
      const body = {
        store_id: storeId,
        menu: {
          type: "food",
          data: [
            ...foodsData.foods,
            {
              items: [],
              category: category,
            },
          ],
        },
      };
      const url = endpoint.admin.menu.root();

      return api.post(url, body).catch((error) => {
        if (error.response.data.error === undefined) {
          return Promise.reject("error");
        }

        return Promise.reject(error.response.data.error);
      });
    } else {
      const body = {
        store_id: storeId,
        menu: {
          type: "food",
          data: [
            {
              items: [],
              category: category,
            },
          ],
        },
      };
      const url = endpoint.admin.menu.root();

      return api.post(url, body).catch((error) => {
        if (error.response.data.error === undefined) {
          return Promise.reject("error");
        }

        return Promise.reject(error.response.data.error);
      });
    }
  }

  async foodDelete({
    foods,
    storeId,
    name,
    category,
  }: { foods: any } & { storeId: number } & { name: string } & {
    category: string;
  }) {
    const removefood = foods.map((foodItem: any) => {
      if (foodItem.category === category) {
        return {
          category: foodItem.category,
          items: foodItem.items.filter((item: any) => {
            return item.name !== name;
          }),
        };
      }
      return { category: foodItem.category, items: foodItem.items };
    });

    const body = {
      store_id: storeId,
      menu: {
        type: "food",
        data: removefood,
      },
    };
    const url = endpoint.admin.menu.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  async foodCategoryDelete({
    foods,
    storeId,
    category,
  }: { foods: any } & { storeId: number } & {
    category: string;
  }) {
    const removefood = foods.filter((foodItem: any) => {
      return foodItem.category !== category;
    });

    const body = {
      store_id: storeId,
      menu: {
        type: "food",
        data: removefood,
      },
    };
    const url = endpoint.admin.menu.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }
}

export default new FoodRepository();
