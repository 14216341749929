import { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import routes from "./routes";
import { resetPending } from "utils/api";
import PrivateRoute from "utils/PrivateRoute";
import SetCurrentStore from "utils/SetCurrentStore";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <HistoryListener>
        <Switch>
          {Object.values(routes.common).map((route, index) => (
            <Route
              key={index}
              path={route.pathname}
              component={route.Component}
              exact
            />
          ))}
          {Object.values(routes.admin).map(({ pathname, Component }, index) => (
            <PrivateRoute
              key={index}
              path={pathname}
              component={(props) => (
                <SetCurrentStore>
                  <Component {...props} />
                </SetCurrentStore>
              )}
            />
          ))}
          <Route
            render={() => <Redirect to={routes.common.signInAdmin.pathname} />}
          />
        </Switch>
      </HistoryListener>
    </BrowserRouter>
  );
};

const HistoryListener: React.FC = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      // ページ遷移時にリクエストのペンディングリストをリセットする
      resetPending();
    });
  });
  return <>{children}</>;
};
