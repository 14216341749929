import React, { useState } from "react";
import { Form, Formik } from "formik";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormikTextField } from "formik-material-fields";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { externalAuthRepository } from "domain/repository";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import Loader from "components/atoms/Loader";
import { Snackbar } from "@material-ui/core";

interface Props {
  vendor: string;
  storeId: number;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loading: {
    width: "100vw",
    height: "100vh",
  },
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ExternalAuthForm: React.VFC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isLinkOk, setIsLinkOk] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  const { vendor, storeId, name } = props;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const LinkMessage = () => {
    if (isLinkOk === null) {
      return null;
    }
    if (isLinkOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {name}とのアカウント連携が完了しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            アカウント連携に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {name}
        </Typography>
        <Formik
          initialValues={{
            vendor,
            storeId,
            id: "",
            password: "",
          }}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values) => {
            setLoading(true);
            externalAuthRepository
              .externalAuthConnect({
                vendor,
                storeId,
                id: values.id,
                password: values.password,
              })
              .then(() => {
                setLoading(false);
                setIsLinkOk(true);
                setOpen(true);
              })
              .catch((error) => {
                setIsLinkOk(false);
                setOpen(true);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          {(formik) => (
            <Form
              id={vendor}
              onSubmit={formik.handleSubmit}
              className={classes.form}
            >
              <FormikTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="id"
                label="ID"
                name="id"
                value={formik.values.id}
                onChange={formik.handleChange}
                error={formik.errors.id}
                size="small"
                autoFocus
              />
              <FormikTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
                autoComplete="current-password"
              />
              <PrimaryButton form={vendor}>登録する</PrimaryButton>
            </Form>
          )}
        </Formik>
        {loading ? (
          <div className={classes.loading}>
            <Loader />
          </div>
        ) : (
          <></>
        )}
        <LinkMessage />
      </div>
    </Container>
  );
};

export default ExternalAuthForm;
