import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  ListItemText,
  Card,
  CardContent,
  Divider,
  Typography,
  Box,
} from "@material-ui/core";
import { Course } from "domain/model/Menu";

type Props = {
  course: Course | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    menuText: {
      flex: "inherit",
    },
    line: {
      flex: 1,
      backgroundImage:
        "linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)",
      backgroundPosition: "bottom",
      backgroundSize: "5px 100px",
      backgroundRepeat: "repeat-x",
    },
    card: {
      borderTop: "3px solid #000",
      borderBottom: "none",
      borderRight: "none",
      borderLeft: "none",
      borderRadius: 0,
      backgroundColor: "#F4F1E5",
    },
  })
);

const MenuCourse: React.FC<Props> = ({ course }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="menu title">
        <ListItem>
          <ListItemText primary={course?.title} className={classes.menuText} />
          <Divider className={classes.line} />
          <ListItemText
            primary={course?.realPrice}
            className={classes.menuText}
          />
          <ListItemText primary="（税込）" className={classes.menuText} />
        </ListItem>
        <Box pl={2}>
          <Card className={classes.card} variant="outlined">
            <CardContent>
              <Typography>{course?.content}</Typography>
            </CardContent>
          </Card>
        </Box>
      </List>
    </div>
  );
};

export default MenuCourse;
