import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { DefaultTemplate } from "components/templates/DefaultTemplate";
import MenuCreateTemplate from "components/templates/MenuCreateTemplate";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/menu-tool";

export type Props = {};

const Container: React.FC<Props> = () => {
  const useContainer = UserContainer.useContainer();
  const { isAdmin } = useContainer;

  const { storeId } = useParams<StoreIdParam>();
  const storeid = Number(storeId);

  return (
    <>
      <MenuCreateTemplate storeId={storeid} />
    </>
  );
};

const MenuCreate: React.FC<Props> = () => {
  return (
    <DefaultTemplate title="メニュー表作成">
      <Container />
    </DefaultTemplate>
  );
};

export default MenuCreate;
