import { plainToClass } from "class-transformer";
import api from "utils/api";
import endpoint from "utils/endpoint";
import { UserType } from "config/menu-tool";
import AdminUser from "domain/model/User/Admin";

class UserService {
  public signIn(email: string, password: string, type: UserType) {
    const body = { session: { email, password } };

    return api.post(endpoint.admin.user.signIn(), body);
  }
  public async fetchAdminUser(): Promise<AdminUser> {
    return await api.get(endpoint.admin.user.users()).then(
      async (res) =>
        await plainToClass(AdminUser, res.data, {
          excludeExtraneousValues: true,
        })
    );
  }
}

export default new UserService();
