import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Paper, Snackbar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { PrimaryButton } from "../atoms/buttons/PrimaryButton";
import { FormikTextField } from "formik-material-fields";
import { Formik, Form } from "formik";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { useFoods } from "hooks/useMenu";
import { foodRepository } from "domain/repository";
import Loader from "components/atoms/Loader";
import FoodListTemplate from "components/templates/FoodListTemplate";
import { Food } from "domain/model/Menu";
import { useHistory } from "react-router-dom";

type Props = {
  storeId: number;
  foods: Food[] | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formText: {
      alignSelf: "center",
    },
    submitButton: {
      marginTop: "24px",
    },
    buttons: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(-2),
    },
    title: { textAlign: "center" },
    textBottom: { verticalAlign: "bottom" },
    radioForm: { flexDirection: "row" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    helperText: {
      display: "flex",
      justifyContent: "space-between",
    },
    loading: {
      width: "100vw",
      height: "100vh",
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const FoodCategoryForm: React.VFC<Props> = ({ storeId, foods }) => {
  const foodsData = useFoods({ storeId });
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [headlineCount, setHeadlineCount] = useState(0);

  const [open, setOpen] = useState<boolean>(false);
  const [isCreateOk, setIsCreateOk] = useState<boolean | null>(null);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const storeid = String(storeId);

  const CreateMessage = () => {
    if (isCreateOk === null) {
      return null;
    }
    if (isCreateOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              料理の見出しを作成しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            作成に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      <Paper>
        <Box m={4} pt={2} pb={4}>
          <Formik
            initialValues={{
              category: "",
            }}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values) => {
              setLoading(true);
              foodRepository
                .foodCategoryCreate({
                  foodsData,
                  storeId,
                  category: values.category,
                })
                .then(() => {
                  setLoading(false);
                  setIsCreateOk(true);
                  setOpen(true);
                })
                .catch((error) => {
                  setIsCreateOk(false);
                  setOpen(true);
                })
                .finally(() => {
                  setLoading(false);
                  history.go(0);
                });
            }}
          >
            {(formik) => (
              <Form id="foodCategoryCreate" onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  justify="space-around"
                  direction="row"
                  spacing={2}
                >
                  <Grid item xs={10} sm={9} md={9}>
                    <FormikTextField
                      name="category"
                      id="category"
                      variant="outlined"
                      maxLength={40}
                      label="追加する見出しを入力してください"
                      helperText={
                        <Box className={classes.helperText}>
                          <p>40文字以内で入力してください</p>
                          <p>{headlineCount}/40</p>
                        </Box>
                      }
                      value={formik.values.category}
                      onChange={
                        (formik.handleChange,
                        (e: any) => setHeadlineCount(e.target.value.length))
                      }
                      error={formik.errors.category}
                      size="small"
                      fullWidth
                      characterLimit={40}
                      inputProps={{
                        maxlength: "40",
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    <div className={classes.buttons}>
                      <PrimaryButton form="foodCategoryCreate">
                        見出しを新規追加
                      </PrimaryButton>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
        <FoodListTemplate storeId={storeid} foods={foods} />
      </Paper>
      {loading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <CreateMessage />
    </>
  );
};
