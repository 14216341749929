import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { PrimaryButton } from "../atoms/buttons/PrimaryButton";
import { SecondaryButton } from "../atoms/buttons/SecondaryButton";
import { Box, Snackbar, Typography } from "@material-ui/core";
import { FormikTextField } from "formik-material-fields";
import { Formik, Form } from "formik";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { DrinkDetail } from "../molecules/DrinkDetail";
import { Drink, Items } from "domain/model/Menu";
import { drinkRepository, imageRepository } from "domain/repository";
import DropImage from "../molecules/DropImage";
import Loader from "components/atoms/Loader";

type Props = {
  storeId: number;
  items: any | null;
  category: string;
  index: number;
  drinks: Drink[] | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    imageContainer: {
      marginRight: theme.spacing(1),
    },
    menuContainer: {
      width: "100%",
      border: "1px solid #aaa",
      borderRadius: "8px",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      alignItems: "center",
    },
    form: {
      width: "100%",
    },
    name: {
      marginRight: theme.spacing(1),
    },
    price: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    priceInput: {
      marginTop: "-28px",
    },
    drinkDetail: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    helperText: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      marginTop: "-28px",
    },
    loading: {
      width: "100vw",
      height: "100vh",
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const DrinkHeading: React.VFC<Props> = ({
  storeId,
  items,
  category,
  index,
  drinks,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [headlineCount, setHeadlineCount] = useState(0);
  const [desCount, setDesCount] = useState(0);

  const [open, setOpen] = useState<boolean>(false);
  const [isCreateOk, setIsCreateOk] = useState<boolean | null>(null);
  const [isDeleteOk, setIsDeleteOk] = useState<boolean | null>(null);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [image, setImage] = useState("");
  const [files, setFiles] = useState([]);

  const handleDelete = () => {
    setLoading(true);
    drinkRepository
      .drinkCategoryDelete({
        drinks,
        storeId,
        category,
      })
      .then(() => {
        setLoading(false);
        setIsDeleteOk(true);
        setOpen(true);
      })
      .catch((error) => {
        setIsDeleteOk(false);
        setOpen(true);
      })
      .finally(() => {
        setLoading(false);
        history.go(0);
      });
  };

  const CreateMessage = () => {
    if (isCreateOk === null) {
      return null;
    }
    if (isCreateOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              ドリンクを作成しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            作成に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  const DeleteMessage = () => {
    if (isDeleteOk === null) {
      return null;
    }
    if (isDeleteOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              見出しを削除しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            削除に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        className={classes.menuContainer}
        justify="space-around"
        direction="row"
      >
        <ListItem button>
          <Grid item xs={12} sm={7} md={7}>
            <Typography>見出し：{category}</Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <SecondaryButton onClick={() => handleDelete()}>
              <DeleteIcon />
              この見出しを全て削除
            </SecondaryButton>
          </Grid>
        </ListItem>
        <Formik
          initialValues={{
            category: category,
            name: "",
            price: "",
            description: "",
          }}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values) => {
            setLoading(true);
            imageRepository
              .imageCreate({ storeId, menuType: "drink", files: files[0] })
              .then(() => {
                drinkRepository
                  .drinkCreate({
                    drinks,
                    storeId,
                    category: category,
                    name: values.name,
                    price: values.price,
                    description: values.description,
                    image: `images/menus/drink/stores/${String(
                      storeId
                    )}/${image}`,
                  })
                  .then(() => {
                    setLoading(false);
                    setIsCreateOk(true);
                    setOpen(true);
                  })
                  .catch((error) => {
                    setIsCreateOk(false);
                    setOpen(true);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              })
              .catch((error) => {
                setIsCreateOk(false);
                setOpen(true);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          {(formik) => (
            <Form
              id={`drinkDetailCreate${index}`}
              onSubmit={formik.handleSubmit}
              className={classes.form}
            >
              {" "}
              <Grid
                container
                className={classes.menuContainer}
                justify="space-around"
                direction="row"
              >
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  className={classes.imageContainer}
                >
                  <DropImage setImage={setImage} setFiles={setFiles} />
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <Grid
                    container
                    className={classes.menuContainer}
                    direction="row"
                  >
                    <Grid item xs={12} sm={9} md={9}>
                      <FormikTextField
                        name="name"
                        id="name"
                        variant="outlined"
                        maxLength={50}
                        label="ドリンク名を追加してください"
                        helperText={
                          <Box className={classes.helperText}>
                            <p>50文字以内で入力してください</p>
                            <p>{headlineCount}/50</p>
                          </Box>
                        }
                        value={formik.values.name}
                        onChange={
                          (formik.handleChange,
                          (e: any) => setHeadlineCount(e.target.value.length))
                        }
                        error={formik.errors.name}
                        size="small"
                        fullWidth
                        className={classes.name}
                        haracterLimit={50}
                        inputProps={{
                          maxlength: "50",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      className={classes.priceInput}
                    >
                      <FormikTextField
                        name="price"
                        id="price"
                        variant="outlined"
                        maxLength={8}
                        label="価格"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.errors.price}
                        size="small"
                        fullWidth
                        className={classes.price}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <FormikTextField
                        name="description"
                        id="description"
                        variant="outlined"
                        maxLength={1000}
                        label="内容"
                        helperText={
                          <Box className={classes.helperText}>
                            <p>1000文字以内で入力してください</p>
                            <p>{desCount}/1000</p>
                          </Box>
                        }
                        value={formik.values.description}
                        onChange={
                          (formik.handleChange,
                          (e: any) => setDesCount(e.target.value.length))
                        }
                        error={formik.errors.description}
                        size="small"
                        fullWidth
                        characterLimit={1000}
                        inputProps={{
                          maxlength: "1000",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={classes.button}>
                      <PrimaryButton form={`drinkDetailCreate${index}`}>
                        <CreateIcon />
                        追加
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
            </Form>
          )}
        </Formik>
        {items?.items.map((item: Items, key: number) => (
          <div className={classes.drinkDetail}>
            <DrinkDetail
              drinks={drinks}
              category={category}
              storeId={storeId}
              name={item.name}
              price={item.price}
              description={item.description}
              image={item.image}
              key={key}
            />
          </div>
        ))}
      </Grid>
      {loading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <CreateMessage />
      <DeleteMessage />
    </>
  );
};
