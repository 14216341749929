import api from "utils/api";
import endpoint from "utils/endpoint";

class ImageRepository {
  public async imageCreate({
    storeId,
    menuType,
    files,
  }: { storeId: number } & { menuType: string } & { files: File }) {
    const url = endpoint.admin.image.root();

    const formData = new FormData();
    formData.append("images[]", files);
    formData.append("store_id", String(storeId));
    formData.append("menu_type", menuType);

    return api.post(url, formData).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  public async imageDelete(id: number) {
    const url = endpoint.admin.image.delete(id.toString());
    return api.delete(url);
  }
}

export default new ImageRepository();
