import { DefaultTemplate } from "components/templates/DefaultTemplate";
import CourseCreateForm from "components/templates/CourseCreateForm";
import { StoreIdParam } from "config/menu-tool";
import React from "react";
import { useParams } from "react-router";

const CourseCreate: React.FC = () => {
  const { storeId } = useParams<StoreIdParam>();
  const storeid = Number(storeId);

  return (
    <DefaultTemplate title="コース新規作成">
      <CourseCreateForm storeId={storeid} />
    </DefaultTemplate>
  );
};

export default CourseCreate;
