import React, { useState, useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import routes from "router/routes";
import { courseService } from "domain/service";
import { Course } from "domain/model/Menu";
import { DefaultTemplate } from "components/templates/DefaultTemplate";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/menu-tool";
import CourseListTemplate from "components/templates/CourseListTemplate";

export type Props = {};

const Container: React.FC<Props> = () => {
  const useContainer = UserContainer.useContainer();
  const { isAdmin } = useContainer;
  const [courses, setCourses] = useState<Course[] | null>(null);

  const { storeId } = useParams<StoreIdParam>();

  const staffNewEditUrl = generatePath(routes.admin.courseCreate.pathname, {
    storeId,
  });

  const history = useHistory();

  useEffect(() => {
    const fetchCourses = () => {
      courseService.fetchAllCourse(storeId).then((res) => setCourses(res));
    };
    fetchCourses();
  }, [storeId]);

  return (
    <>
      <PrimaryButton onClick={() => history.push(staffNewEditUrl)}>
        コース新規作成
      </PrimaryButton>
      <CourseListTemplate
        isAdmin={isAdmin}
        storeId={storeId}
        courses={courses}
      />
    </>
  );
};

const CourseList: React.FC<Props> = () => {
  return (
    <DefaultTemplate title="コース一覧">
      <Container />
    </DefaultTemplate>
  );
};

export default CourseList;
