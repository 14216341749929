import api from "utils/api";
import endpoint from "utils/endpoint";

class UserRepository {
  async accountUpdate(
    name: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    id: number
  ) {
    const body = {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
    };

    return api.put(endpoint.admin.user.update(String(id)), body);
  }
}

export default new UserRepository();
