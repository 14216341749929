import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import StoreStaffUser from "domain/model/User/StoreStaff";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { SnackbarContent } from "@material-ui/core";
import { theme } from "ui/theme";

type Props = {
  stores: StoreStaffUser[];
  moveDashboard: Function;
};

interface Column {
  id: "storeId" | "storeName" | "buttons";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "storeId", label: "店舗ID", minWidth: 80 },
  { id: "storeName", label: "店舗名", minWidth: 240 },
  {
    id: "buttons",
    label: "",
    minWidth: 170,
    align: "right",
  },
];

const useStyles = makeStyles({
  root: {
    width: "90%",
    display: "block",
    margin: "1rem auto 0 auto",
  },
  container: {
    height: "70vh",
  },
  message: {
    margin: theme.spacing(2),
  },
});

export const StoreListTemplate: React.FC<Props> = memo(
  ({ stores, moveDashboard }) => {
    const classes = useStyles();

    return stores.length > 0 ? (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stores.map((store, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell>{store.id}</TableCell>
                    <TableCell>{store.storeName}</TableCell>
                    <TableCell>
                      <PrimaryButton onClick={() => moveDashboard(store.id)}>
                        ダッシュボード
                      </PrimaryButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    ) : (
      <>
        <SnackbarContent
          message="データが存在しません。"
          className={classes.message}
        />
      </>
    );
  }
);
