import * as React from "react";
import { Link } from "react-router-dom";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { CardActionArea } from "@material-ui/core";

type Props = {
  image?: any;
  title?: string;
  name: string;
  description?: string;
  link: string;
};

export const MenuCard = ({ image, title, name, description, link }: Props) => {
  return (
    <Card>
      <CardActionArea component={Link} to={link}>
        <CardMedia image={image} title={title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
