import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import StoreCreateForm from "components/templates/StoreCreateForm";
import StoreHeader from "components/organisms/StoreHeader";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0.5rem 2.8rem 0 2.8rem",
    },
  })
);

const StoreCreate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StoreHeader />
      <Box className={classes.title}>
        <Typography variant="h6" component="h1">
          店舗一覧
        </Typography>
      </Box>
      <StoreCreateForm />
    </div>
  );
};

export default StoreCreate;
