import { plainToClass } from "class-transformer";

import api from "utils/api";
import endpoint from "utils/endpoint";
import { Course } from "domain/model/Menu";

class CourseService {
  public async fetchAllCourse(storeId: string | number): Promise<Course[]> {
    return api
      .get(endpoint.admin.menu.root(), { params: { store_id: storeId } })
      .then((res) => {
        return res?.data?.course?.map((d: Course) =>
          plainToClass(Course, d, { excludeExtraneousValues: true })
        );
      });
  }
}

export default new CourseService();
