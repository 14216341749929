/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Drawer, Link, Icon, Menu, MenuItem } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import {
  Typography,
  Divider,
  Container,
  Collapse,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { PrimaryMenu } from "components/molecules/menu/PrimaryMenu";
import { SecondaryMenu } from "components/molecules/menu/SecondaryMenu";
import { CopyRight } from "components/molecules/CopyRight";
import ExpandableItem from "utils/expandableItem";
import { useCallback } from "react";
import { StoreContainer, UserContainer } from "containers";
import { adminMenus, SidebarConfig } from "config/sidebarConfig";

type MenuItemProps = {
  main: SidebarConfig;
  xprops: any;
  open: boolean;
};

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
    },
    drawerPaperClose: {
      overflowX: "hidden",
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    logo: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    logoLink: {
      textDecoration: "none",
    },
    logout: {
      color: "white",
    },
  })
);

export interface DefaultTemplateProps {
  children: React.ReactNode;
  title: string;
}

export const DefaultTemplate: React.FC<DefaultTemplateProps> = ({
  children,
  title,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const history = useHistory();

  const onClickHome = useCallback(() => history.push(""), []);

  const userContainer = UserContainer.useContainer();
  const { userType } = userContainer;

  const currentStore = StoreContainer.useContainer().currentStore;

  const storeId = currentStore?.id;

  if (userType === "admin" && storeId === undefined) {
    return null;
  }

  const { signOut } = userContainer;

  const menus = adminMenus(String(storeId));

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {currentStore?.storeName}
          </Typography>
          <Button
            className={classes.logout}
            onClick={() => signOut()}
            startIcon={<ExitToAppIcon />}
          >
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Link
            component="button"
            onClick={onClickHome}
            className={classes.logoLink}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.logo}
            >
              メニュー管理ツール
            </Typography>
          </Link>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ExpandableItem
            render={(xprops: any) => (
              <>
                {menus.map((main, index) => (
                  <MenuItems
                    key={index}
                    main={main}
                    xprops={xprops}
                    open={open}
                  />
                ))}
              </>
            )}
          />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            className={classes.pageTitle}
          >
            {title}
          </Typography>
          {children}
          <Box pt={2}>
            <CopyRight />
          </Box>
        </Container>
      </main>
    </div>
  );
};

const MenuItems: React.FC<MenuItemProps> = ({ main, xprops, open }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {open ? (
        <PrimaryMenu
          title={main.title}
          onClick={() => xprops.setOpen(!xprops.open)}
          open={xprops.open}
          expand={true}
        >
          <Icon>{main.icon}</Icon>
        </PrimaryMenu>
      ) : (
        <div>
          <Button
            aria-controls={main.title}
            aria-haspopup="true"
            onClick={handleClick}
            style={{ color: "rgba(0, 0, 0, 0.54)", padding: "8px 16px" }}
          >
            <Icon>{main.icon}</Icon>
          </Button>
          <Menu
            id={main.title}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {main.subMenu &&
              main.subMenu.map((sub, i) => (
                <Link href={sub.path}>
                  <MenuItem key={i}>{sub.title}</MenuItem>
                </Link>
              ))}
          </Menu>
        </div>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Collapse in={xprops.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {main.subMenu &&
              open &&
              main.subMenu.map((sub, i) => (
                <SecondaryMenu key={i} link={sub.path} title={sub.title} />
              ))}
          </List>
        </Collapse>
      </Collapse>
    </>
  );
};
