import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  FormLabel,
  Paper,
  Box,
  Snackbar,
} from "@material-ui/core";
import { FormikTextField, FormikSelectField } from "formik-material-fields";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MESSAGE from "config/message.json";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import DropImage from "../molecules/DropImage";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { SecondaryButton } from "components/atoms/buttons/SecondaryButton";

import { courseRepository, imageRepository } from "domain/repository";
import { TimeZoneOptions } from "components/atoms/formInputs";
import { useCourses } from "hooks/useMenu";
import routes from "router/routes";
import Loader from "components/atoms/Loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formText: {
      alignSelf: "center",
    },
    submitButton: {
      marginTop: "24px",
    },
    buttons: {
      display: "flex",
      justifyContent: "center",
    },
    title: { textAlign: "center" },
    titleHelper: { marginBottom: theme.spacing(2) },
    textBottom: { verticalAlign: "bottom" },
    radioForm: { flexDirection: "row" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    helperText: {
      display: "flex",
      justifyContent: "space-between",
    },
    image: {
      width: "100vw",
    },
    loading: {
      width: "100vw",
      height: "100vh",
    },
  })
);

enum CoursePublishedOptions {
  掲載,
  非掲載,
}

enum CourseTypeOptions {
  コース料理,
  席のみ,
}

enum ReservationOptions {
  対応,
  非対応,
}

const StayTimeOptions = [
  "1時間",
  "1時間30分",
  "2時間",
  "2時間30分",
  "3時間",
  "3時間30分",
];

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type Props = { storeId: number };

const validationSchema = Yup.object().shape({
  number: Yup.object({
    min: Yup.string()
      .required(MESSAGE.ERROR.NO_INPUT)
      .min(1, "1から99までの数値を入力してください")
      .max(99, "1から99までの数値を入力してください"),
    max: Yup.string()
      .required(MESSAGE.ERROR.NO_INPUT)
      .min(1, "1から99までの数値を入力してください")
      .max(99, "1から99までの数値を入力してください"),
  }),
});

const CourseCreateForm: React.FC<Props> = ({ storeId }) => {
  const courses = useCourses({ storeId });
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");

  const history = useHistory();
  const [files, setFiles] = useState([]);

  const [titleCount, setTitleCount] = useState(0);
  const [desCount, setDesCount] = useState(0);
  const [contentCount, setContentCount] = useState(0);

  const [open, setOpen] = useState<boolean>(false);
  const [isCreateOk, setIsCreateOk] = useState<boolean | null>(null);

  const courseListPath = generatePath(routes.admin.courseList.pathname, {
    storeId,
  });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const CreateMessage = () => {
    if (isCreateOk === null) {
      return null;
    }
    if (isCreateOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              コースを作成しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            作成に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      <Paper>
        <Box m={4} pt={2} pb={4}>
          <Formik
            initialValues={{
              title: "",
              realPrice: "",
              description: "",
              content: "",
              number: {
                min: "",
                max: "",
              },
              time: {
                start: "",
                end: "",
              },
            }}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setLoading(true);
              imageRepository
                .imageCreate({
                  storeId,
                  menuType: "course",
                  files: files[0],
                })
                .then(() => {
                  courseRepository
                    .courseCreate({
                      courses,
                      storeId,
                      title: values.title,
                      realPrice: values.realPrice,
                      description: values.description,
                      content: values.content,
                      number: {
                        min: values.number.min,
                        max: values.number.max,
                      },
                      time: {
                        start: values.time.start,
                        end: values.time.end,
                      },
                      image: `images/menus/course/stores/${String(
                        storeId
                      )}/${image}`,
                    })
                    .then(() => {
                      setLoading(false);
                      setIsCreateOk(true);
                      setOpen(true);
                    })
                    .catch((error) => {
                      setIsCreateOk(false);
                      setOpen(true);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                  actions.setFieldValue("files", []);
                })
                .catch((error) => {
                  setIsCreateOk(false);
                  setOpen(true);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            {(formik) => (
              <Form id="courseCreate" onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  justify="space-around"
                  direction="row"
                  spacing={2}
                >
                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    <FormLabel
                      className={classes.titleHelper}
                      component="legend"
                      required
                    >
                      コース名
                    </FormLabel>
                  </Grid>
                  <Grid item xs={10} sm={9} md={9}>
                    <FormikTextField
                      name="title"
                      id="title"
                      variant="outlined"
                      maxLength={45}
                      helperText={
                        <Box className={classes.helperText}>
                          <p>45文字以内で入力してください</p>
                          <p>{titleCount}/45</p>
                        </Box>
                      }
                      value={formik.values.title}
                      onChange={
                        (formik.handleChange,
                        (e: any) => setTitleCount(e.target.value.length))
                      }
                      error={formik.errors.title}
                      size="small"
                      fullWidth
                      characterLimit={45}
                      inputProps={{
                        maxlength: "45",
                      }}
                    />
                  </Grid>

                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    <FormLabel component="legend" required>
                      コース料金
                    </FormLabel>
                  </Grid>
                  <Grid item xs={10} sm={4} md={4}>
                    <FormikTextField
                      fullWidth
                      name="realPrice"
                      id="realPrice"
                      value={formik.values.realPrice}
                      rows={4}
                      variant="outlined"
                      onChange={formik.handleChange}
                      error={formik.errors.realPrice}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={10} sm={5} md={5} className={classes.formText}>
                    円（税込）
                  </Grid>

                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    <FormLabel
                      className={classes.titleHelper}
                      component="legend"
                      required
                    >
                      特徴
                    </FormLabel>
                  </Grid>
                  <Grid item xs={10} sm={9} md={9}>
                    <FormikTextField
                      name="description"
                      required
                      variant="outlined"
                      id="description"
                      helperText={
                        <Box className={classes.helperText}>
                          <p>180文字以内で入力してください</p>
                          <p>{desCount}/180</p>
                        </Box>
                      }
                      value={formik.values.description}
                      onChange={
                        (formik.handleChange,
                        (e: any) => setDesCount(e.target.value.length))
                      }
                      error={formik.errors.description}
                      fullWidth
                      multiline
                      rows="4"
                      characterLimit={180}
                      inputProps={{
                        maxlength: "180",
                      }}
                    />
                  </Grid>

                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    <FormLabel
                      className={classes.titleHelper}
                      component="legend"
                      required
                    >
                      コースの内容
                    </FormLabel>
                  </Grid>
                  <Grid item xs={10} sm={9} md={9}>
                    <FormikTextField
                      name="content"
                      required
                      id="content"
                      helperText={
                        <Box className={classes.helperText}>
                          <p>300文字以内で入力してください</p>
                          <p>{contentCount}/300</p>
                        </Box>
                      }
                      variant="outlined"
                      value={formik.values.content}
                      onChange={
                        (formik.handleChange,
                        (e: any) => setContentCount(e.target.value.length))
                      }
                      error={formik.errors.content}
                      fullWidth
                      multiline
                      rows="4"
                      characterLimit={300}
                      inputProps={{
                        maxlength: "300",
                      }}
                    />
                  </Grid>

                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    <FormLabel component="legend" required>
                      注文可能人数
                    </FormLabel>
                  </Grid>
                  <Grid item xs={10} sm={2} md={2}>
                    <FormikTextField
                      name="number.min"
                      id="number.min"
                      value={formik.values.number.min}
                      type="text"
                      onChange={formik.handleChange}
                      error={formik?.errors?.number?.min}
                      variant="outlined"
                      size="small"
                      InputProps={{ inputProps: { min: 1, max: 99 } }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={2} md={2} className={classes.formText}>
                    名から
                  </Grid>

                  <Grid item xs={10} sm={2} md={2}>
                    <FormikTextField
                      name="number.max"
                      id="number.max"
                      value={formik.values.number.max}
                      type="text"
                      onChange={formik.handleChange}
                      error={formik?.errors?.number?.max}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    名まで
                  </Grid>

                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    <FormLabel component="legend" required>
                      注文受付時間
                    </FormLabel>
                  </Grid>
                  <Grid item xs={10} sm={2} md={2}>
                    <FormikSelectField
                      name="time.start"
                      id="time.start"
                      variant="outlined"
                      value={formik.values.time.start}
                      options={TimeZoneOptions}
                      onChange={formik.handleChange}
                      error={formik?.errors?.time?.start}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={10} sm={2} md={2} className={classes.formText}>
                    から
                  </Grid>
                  <Grid item xs={10} sm={2} md={2}>
                    <FormikSelectField
                      name="time.end"
                      id="time.end"
                      variant="outlined"
                      value={formik.values.time.end}
                      options={TimeZoneOptions}
                      onChange={formik.handleChange}
                      error={formik?.errors?.time?.end}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={10} sm={3} md={3} className={classes.formText}>
                    まで
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.image}
                  >
                    <DropImage setImage={setImage} setFiles={setFiles} />
                  </Grid>

                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    className={classes.submitButton}
                  >
                    <div className={classes.buttons}>
                      <PrimaryButton form="courseCreate" disabled={loading}>
                        登録
                      </PrimaryButton>
                      <SecondaryButton
                        onClick={() => history.push(courseListPath)}
                      >
                        キャンセル
                      </SecondaryButton>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
      {loading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <CreateMessage />
    </>
  );
};

export default CourseCreateForm;
