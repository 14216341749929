export const TimeZoneOptions = [
  { label: "6:00", value: "0600" },
  { label: "6:30", value: "0630" },
  { label: "7:00", value: "0700" },
  { label: "7:30", value: "0730" },
  { label: "8:00", value: "0800" },
  { label: "8:30", value: "0830" },
  { label: "9:00", value: "0900" },
  { label: "9:30", value: "0930" },
  { label: "10:00", value: "1000" },
  { label: "10:30", value: "1030" },
  { label: "11:00", value: "1100" },
  { label: "11:30", value: "1130" },
  { label: "12:00", value: "1200" },
  { label: "12:30", value: "1230" },
  { label: "13:00", value: "1300" },
  { label: "13:30", value: "1330" },
  { label: "14:00", value: "1400" },
  { label: "14:30", value: "1430" },
  { label: "15:00", value: "1500" },
  { label: "15:30", value: "1530" },
  { label: "16:00", value: "1600" },
  { label: "16:30", value: "1630" },
  { label: "17:00", value: "1700" },
  { label: "17:30", value: "1730" },
  { label: "18:00", value: "1800" },
  { label: "18:30", value: "1830" },
  { label: "19:00", value: "1900" },
  { label: "19:30", value: "1930" },
  { label: "20:00", value: "2000" },
  { label: "20:30", value: "2030" },
  { label: "21:00", value: "2100" },
  { label: "21:30", value: "2130" },
  { label: "22:00", value: "2200" },
  { label: "22:30", value: "2230" },
  { label: "23:00", value: "2300" },
  { label: "23:30", value: "2330" },
  { label: "24:00", value: "2400" },
  { label: "24:30", value: "2430" },
  { label: "25:00", value: "2500" },
  { label: "25:30", value: "2530" },
  { label: "26:00", value: "2600" },
  { label: "26:30", value: "2630" },
  { label: "27:00", value: "2700" },
  { label: "27:30", value: "2730" },
  { label: "28:00", value: "2800" },
  { label: "28:30", value: "2830" },
  { label: "29:00", value: "2900" },
  { label: "29:30", value: "2930" },
];

export const TimeZoneLists = {
  "0600": "6:00",
  "0630": "6:30",
  "0700": "7:00",
  "0730": "7:30",
  "0800": "8:00",
  "0830": "8:30",
  "0900": "9:00",
  "0930": "9:30",
  "1000": "10:00",
  "1030": "10:30",
  "1100": "11:00",
  "1130": "11:30",
  "1200": "12:00",
  "1230": "12:30",
  "1300": "13:00",
  "1330": "13:30",
  "1400": "14:00",
  "1430": "14:30",
  "1500": "15:00",
  "1530": "15:30",
  "1600": "16:00",
  "1630": "16:30",
  "1700": "17:00",
  "1730": "17:30",
  "1800": "18:00",
  "1830": "18:30",
  "1900": "19:00",
  "1930": "19:30",
  "2000": "20:00",
  "2030": "20:30",
  "2100": "21:00",
  "2130": "21:30",
  "2200": "22:00",
  "2230": "22:30",
  "2300": "23:00",
  "2330": "23:30",
  "2400": "24:00",
  "2430": "24:30",
  "2500": "25:00",
  "2530": "25:30",
  "2600": "26:00",
  "2630": "26:30",
  "2700": "27:00",
  "2730": "27:30",
  "2800": "28:00",
  "2830": "28:30",
  "2900": "29:00",
  "2930": "29:30",
};
