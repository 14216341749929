import React, { useState, useEffect, memo } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import routes from "router/routes";
import { StoreListTemplate } from "components/templates/StoreListTemplate";
import StoreHeader from "components/organisms/StoreHeader";
import { CopyRight } from "components/molecules/CopyRight";
import { storeService } from "domain/service";
import { StoreContainer } from "containers";
import StoreStaffUser from "domain/model/User/StoreStaff";
import Loader from "components/atoms/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import Pagination from "components/organisms/Pagination";
import { UserContainer } from "containers";

const useStyles = makeStyles({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.5rem 2.8rem 0 2.8rem",
  },
});

const StoreList = memo(() => {
  const classes = useStyles();

  const { changeCurrentStore } = StoreContainer.useContainer();
  const history = useHistory();

  const [stores, setStores] = useState<StoreStaffUser[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const { page } = useParams<{ page: string }>();
  const pageNumber = Number(page);
  const [searchWord, setSearchWord] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const useContainer = UserContainer.useContainer();
  const { currentUserData } = useContainer;

  const id = currentUserData.store?.id;

  useEffect(() => {
    setLoading(true);
    if (searchWord) {
      // 検索して店舗を取得

      storeService
        .fetchStores({ search: searchWord, page: pageNumber })
        .then((res) => {
          setStores(res.stores);
          setTotal(res.total);
        })

        .finally(() => {
          setLoading(false);
        });
    } else {
      // 店舗を取得
      storeService
        .fetchStores({ page: pageNumber })
        .then((res) => {
          setStores(res.stores);
          setTotal(res.total);
        })
        .catch(() => {
          const refirectPath = generatePath(routes.admin.dashboard.pathname, {
            storeId: Number(id),
          });
          // TODO: location.hrefにする必要がある
          window.location.href = refirectPath;
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, searchWord]);

  const moveDashboard = (storeId: number) => {
    changeCurrentStore(storeId.toString());
    history.push(generatePath(routes.admin.dashboard.pathname, { storeId }));
  };

  const handleSearch = (search: string) => {
    setSearchWord(search);
    const pageUrl = generatePath(routes.admin.storeIndex.pathname, { page: 1 });
    history.push(pageUrl);
  };

  if (loading || stores === null) {
    return <Loader />;
  }

  return (
    <div>
      <StoreHeader />
      <Box className={classes.title}>
        <Typography variant="h6" component="h1">
          店舗一覧
        </Typography>
        <PrimaryButton
          onClick={() => history.push(routes.admin.storeCreate.pathname)}
        >
          新規店舗作成
        </PrimaryButton>
      </Box>
      <StoreListTemplate stores={stores} moveDashboard={moveDashboard} />
      <div>
        <Pagination
          active={pageNumber}
          total={total ?? 0}
          handleChange={(activePage: number) => {
            const pageUrl = generatePath(
              routes.adminAgency.storeIndex.pathname,
              {
                page: activePage,
              }
            );
            history.push(pageUrl);
          }}
        />
      </div>
      <CopyRight />
    </div>
  );
});

export default StoreList;
