import React, { useState, useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import routes from "router/routes";
import { staffService } from "domain/service";
import User from "domain/model/User/User";
import { DefaultTemplate } from "components/templates/DefaultTemplate";
import StaffListTemplate from "components/templates/StaffListTemplate";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/menu-tool";

export type Props = {};

export const RoleTypes = {
  ADMIN: "admin",
  ADMINSTAFF: "adminStaff",
  STORESTAFF: "storeStaff",
};

export type RoleType = typeof RoleTypes[keyof typeof RoleTypes] | undefined;

const Container: React.FC<Props> = () => {
  const useContainer = UserContainer.useContainer();
  const { currentUser, isAdmin } = useContainer;
  const [staffs, setStaffs] = useState<User[]>([]);

  const { storeId } = useParams<StoreIdParam>();
  const staffNewUrl = generatePath(routes.admin.staffNew.pathname, {
    storeId,
  });

  const history = useHistory();
  const userType = currentUser;

  useEffect(() => {
    const fetchStaffs = () => {
      staffService.fetchAllStaff(storeId).then((res) => setStaffs(res));
    };
    fetchStaffs();
  }, [storeId]);

  return (
    <>
      {userType !== RoleTypes.STORESTAFF ? (
        <PrimaryButton onClick={() => history.push(staffNewUrl)}>
          スタッフ追加
        </PrimaryButton>
      ) : (
        <></>
      )}
      <StaffListTemplate
        isAdmin={isAdmin}
        storeId={storeId}
        staffs={staffs}
        userType={userType}
      />
    </>
  );
};

const StaffList: React.FC<Props> = () => {
  return (
    <DefaultTemplate title="スタッフ一覧">
      <Container />
    </DefaultTemplate>
  );
};

export default StaffList;
