import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  })
);

type Props = {
  link?: any;
  title: string;
  open?: boolean;
  expand?: boolean;
  onClick?: () => void | undefined;
  children?: ReactNode | ReactNode[];
};

export const PrimaryMenu = ({
  link,
  title,
  open,
  expand,
  onClick,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Link to={link} className={classes.link}>
        <ListItem button onClick={onClick}>
          <ListItemIcon>{children}</ListItemIcon>
          <ListItemText primary={title} />
          {expand ? open ? <ExpandLess /> : <ExpandMore /> : ""}
        </ListItem>
      </Link>
    </div>
  );
};
