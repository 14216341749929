import { Type, Expose } from "class-transformer";

export class Menu {
  @Type(() => Course)
  @Expose()
  course: Course;

  @Type(() => Food)
  @Expose()
  food: Food;

  @Type(() => Drink)
  @Expose()
  drink: Drink;
}

export class Course {
  @Expose() title: string;

  @Expose({ name: "real_price" })
  realPrice: string;

  @Expose() description: string;
  @Expose() content: string;

  @Type(() => Number)
  @Expose()
  number: Number;

  @Type(() => Time)
  @Expose()
  time: Time;

  @Expose() image: string;
}

class Number {
  @Expose() min: string;
  @Expose() max: string;
}

class Time {
  @Expose() start: string;
  @Expose() end: string;
}

export class Food {
  @Expose() category: string;

  @Type(() => Items)
  @Expose({ name: "items" })
  items: Items;
}

export class Drink {
  @Expose() category: string;

  @Type(() => Items)
  @Expose({ name: "items" })
  items: Items;
}

export class Items {
  [x: string]: any;
  @Expose() name: string;
  @Expose() price: string;
  @Expose() description: string;
  @Expose() image: string;
}
