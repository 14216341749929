import { UserContainer } from "containers";
import { generatePath } from "react-router";

import routes from "router/routes";

export type SidebarConfig = {
  icon: string;
  title: string;
  path?: string;
  disabled?: boolean;
  subMenu?: {
    title: string;
    path: string;
  }[];
};

export const adminMenus = (storeId: string): SidebarConfig[] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentUserData } = UserContainer.useContainer();
  const currentUserRole = currentUserData.role;

  return currentUserRole === "store_staff"
    ? [
        {
          icon: "restaurantIcon",
          title: "データ入稿",
          subMenu: [
            {
              title: "コース一覧",
              path: generatePath(routes.admin.courseList.pathname, { storeId }),
            },
            {
              title: "料理一覧",
              path: generatePath(routes.admin.foodList.pathname, { storeId }),
            },
            {
              title: "ドリンク一覧",
              path: generatePath(routes.admin.drinkList.pathname, { storeId }),
            },
          ],
        },
        {
          icon: "linkIcon",
          title: "アカウント連携",
          subMenu: [
            {
              title: "アカウント連携",
              path: generatePath(routes.admin.externalAuth.pathname, {
                storeId,
              }),
            },
            {
              title: "一括データ入稿",
              path: generatePath(routes.admin.dataSubmission.pathname, {
                storeId,
              }),
            },
          ],
        },
        {
          icon: "menuBookIcon",
          title: "メニュー表",
          subMenu: [
            {
              title: "メニュー表作成",
              path: generatePath(routes.admin.menuCreate.pathname, { storeId }),
            },
          ],
        },
        {
          icon: "settingsIcon",
          title: "設定・管理",
          subMenu: [
            {
              title: "アカウント情報",
              path: generatePath(routes.admin.staffInfo.pathname, { storeId }),
            },
          ],
        },
      ]
    : [
        {
          icon: "restaurantIcon",
          title: "データ入稿",
          subMenu: [
            {
              title: "コース一覧",
              path: generatePath(routes.admin.courseList.pathname, { storeId }),
            },
            {
              title: "料理一覧",
              path: generatePath(routes.admin.foodList.pathname, { storeId }),
            },
            {
              title: "ドリンク一覧",
              path: generatePath(routes.admin.drinkList.pathname, { storeId }),
            },
          ],
        },
        {
          icon: "linkIcon",
          title: "アカウント連携",
          subMenu: [
            {
              title: "アカウント連携",
              path: generatePath(routes.admin.externalAuth.pathname, {
                storeId,
              }),
            },
            {
              title: "一括データ入稿",
              path: generatePath(routes.admin.dataSubmission.pathname, {
                storeId,
              }),
            },
          ],
        },
        {
          icon: "menuBookIcon",
          title: "メニュー表",
          subMenu: [
            {
              title: "メニュー表作成",
              path: generatePath(routes.admin.menuCreate.pathname, { storeId }),
            },
          ],
        },
        {
          icon: "settingsIcon",
          title: "設定・管理",
          subMenu: [
            {
              title: "スタッフ一覧",
              path: generatePath(routes.admin.staffList.pathname, { storeId }),
            },
            {
              title: "アカウント情報",
              path: generatePath(routes.admin.staffInfo.pathname, { storeId }),
            },
          ],
        },
      ];
};
