import { Expose, Transform } from "class-transformer";
import dayjs from "dayjs";

export default class User {
  @Expose() id: number;
  @Expose({ name: "name" }) userName: string;

  @Expose({ name: "email" }) userEmail: string;
  @Expose() role: string;
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  updatedAt: Date;
  @Expose({ name: "created_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  createdAt: Date;
}
