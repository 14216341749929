import React, { useEffect, ReactNode } from "react";
import { useParams } from "react-router-dom";

import { StoreContainer, UserContainer } from "containers";

import { StoreIdParam } from "config/menu-tool";

type Props = {
  children: ReactNode;
};

const SetCurrentStore: React.FC<Props> = ({ children }) => {
  const storeContainer = StoreContainer.useContainer();
  const userContainer = UserContainer.useContainer();

  const { currentStore, changeCurrentStore } = storeContainer;
  const { userType } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  useEffect(() => {
    if (userType === "admin") {
      if (!currentStore || currentStore.id !== Number(storeId)) {
        changeCurrentStore(storeId);
      }
    } else {
      changeCurrentStore(storeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
  // return !currentStore ? <Loader /> : <>{children}</>;
};

export default SetCurrentStore;
