import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ExternalAuthForm from "components/molecules/form/ExternalAuthForm";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

type Props = {
  storeId: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    justifyContent: "space-evenly",
  },
}));

const ExternalAuthTemplate: React.FC<Props> = ({ storeId }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          className={classes.tab}
        >
          <Tab label="食べログ" {...a11yProps(0)} />
          <Tab label="ぐるなび" {...a11yProps(1)} />
          <Tab label="ホットペッパー" {...a11yProps(2)} />
          <Tab label="Google My Business" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ExternalAuthForm vendor="tabelog" storeId={storeId} name="食べログ" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ExternalAuthForm vendor="gurunavi" storeId={storeId} name="ぐるなび" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ExternalAuthForm
          vendor="hotpepper"
          storeId={storeId}
          name="ホットペッパー"
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ExternalAuthForm
          vendor="googleMb"
          storeId={storeId}
          name="Google My Business"
        />
      </TabPanel>
    </div>
  );
};

export default ExternalAuthTemplate;
