import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { DefaultTemplate } from "components/templates/DefaultTemplate";
import StaffDetailForm from "components/templates/StaffDetailForm";
import { StoreIdParam } from "config/menu-tool";

import React from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import routes from "router/routes";

type StaffType = {
  id?: string;
  name?: string;
  email?: string;
};

const StaffDetail: React.FC = () => {
  const { storeId } = useParams<StoreIdParam>();

  const location = useLocation<StaffType>();
  const history = useHistory();
  const url = generatePath(routes.admin.staffList.pathname, {
    storeId,
  });

  const { name, email, id } = location.state;

  return (
    <DefaultTemplate title="スタッフ編集">
      <PrimaryButton onClick={() => history.push(url)}>一覧へ</PrimaryButton>
      {name && email && id ? (
        <StaffDetailForm storeId={storeId} name={name} email={email} id={id} />
      ) : (
        <p>スタッフ情報が存在しません</p>
      )}
    </DefaultTemplate>
  );
};

export default StaffDetail;
