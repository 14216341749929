import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ReactNode } from "react";

interface Props {
  children: ReactNode | ReactNode[];
  onClick?: () => void;
  form?: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newButton: {
      marginLeft: "auto",
      display: "flex",
      margin: "12px 24px 12px 24px",
    },
  })
);

export const PrimaryButton: React.FC<Props> = ({
  children,
  onClick,
  form,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        form={form}
        variant="contained"
        color="primary"
        className={classes.newButton}
        type="submit"
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </div>
  );
};
