import api from "utils/api";
import endpoint from "utils/endpoint";

class MenuSynchronisationRepository {
  async menuSynchronisationAll({
    storeId,
  }: {
    vendors: ["tabelog", "hotpepper"];
  } & { storeId: number }) {
    const body = {
      menu_synchronisation: {
        vendors: ["tabelog", "hotpepper"],
      },
      store_id: storeId,
    };

    const url = endpoint.admin.menuSynchronisation.root();

    return api.post(url, body).catch((error) => {
      if (error.response) {
        return Promise.reject("error");
      }

      return Promise.reject(error);
    });
  }

  async menuSynchronisation({
    vendor,
    storeId,
  }: {
    vendor: string;
  } & { storeId: number }) {
    const body = {
      menu_synchronisation: {
        vendors: [vendor],
      },
      store_id: storeId,
    };

    const url = endpoint.admin.menuSynchronisation.root();

    return api.post(url, body).catch((error) => {
      if (error.response) {
        return Promise.reject("error");
      }

      return Promise.reject(error);
    });
  }
}

export default new MenuSynchronisationRepository();
