import { useState } from "react";
import { createContainer } from "unstated-next";

import { storeService } from "domain/service";
import { CurrentStore } from "config/menu-tool";

const StoreContainer = () => {
  const [currentStore, setCurrentStore] = useState<CurrentStore>(null);

  const changeCurrentStore = async (storeId: string) => {
    if (storeId) {
      return await storeService
        .fetchStore(storeId)
        .then((res) => setCurrentStore(res));
    }
  };
  return {
    currentStore,
    changeCurrentStore,
  };
};

export default createContainer(StoreContainer);
