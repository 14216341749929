import axios, { AxiosRequestConfig, Canceler } from "axios";

import { getToken } from "utils/localStorage";

// 特に時間がかかると思われる API については個別にタイムアウトを設定すること
const timeout = 180 * 1000;

const getBaseURL = () => {
  switch (process.env.Node_ENV) {
    case "development":
      // return "http://localhost:3000";
      return "https://staging.menutool.reivo.info";
    case "production":
      return "http://menutool.reivo.info";
    default:
      return "https://staging.menutool.reivo.info";
  }
};

const headers = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  Authorization: "",
};

const token =
  getToken("admin") || getToken("storeAdmin") || getToken("storeStaff");

if (token) {
  headers.Authorization = `Bearer ${token}`;
}

const api = axios.create({
  baseURL: getBaseURL(),
  headers,
  responseType: "json",
  timeout: timeout,
});

const changeToken = (newToken: string) => {
  api.defaults.headers.common.token = `Bearer ${newToken}`;
};

// 同じリクエストが重複して行われないようにする
let pending: Record<string, null> = {};
const { CancelToken } = axios;

const removePending = (
  request: AxiosRequestConfig,
  cancel: Canceler | null
) => {
  const url = request.url!.replace(request.baseURL!, "/");

  // 重複チェックに使うflagUrlを生成 (url, method, params, data)
  // paramsとdataはmethodによってはundefinedとなる
  const flagUrl = `${url}&${request.method}&${JSON.stringify(
    request.params
  )}&${JSON.stringify(request.data)}`;

  // pending中のリクエストと全く同じリクエストは、キャンセルする
  if (flagUrl in pending) {
    if (cancel) {
      // リクエスト時の処理
      // リクエストをキャンセル
      cancel(JSON.stringify(request));
    } else {
      // レスポンス時の処理
      // レスポンスが返ってきたので、pendingから消す
      delete pending[flagUrl];
    }
  } else {
    // pending中のリクエストに同じリクエストが存在しないので、リクエストを行う
    // レスポンスが返ってくるまでは、pendingにflagUrlを入れておく
    if (cancel) {
      // log
      if (process.env.NODE_ENV === "development") {
        console.info("Starting Request: ", request);
      }
      pending[flagUrl] = null;
    }
  }
};

// リクエスト用
api.interceptors.request.use(
  (request) => {
    // 重複リクエストチェック
    request.cancelToken = new CancelToken((cancel) =>
      removePending(request, cancel)
    );
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

// レスポンス用
api.interceptors.response.use(
  (response) => {
    // 開発環境ではリクエストログを出力する
    if (process.env.NODE_ENV === "development") {
      console.info("Response: ", response);
    }

    // pendingから該当のrequestを消す
    removePending(response.config, null);

    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      const canceledRequest = JSON.parse(error.message);
      return Promise.reject(
        `Canceled - ${canceledRequest.method.toUpperCase()}: ${
          canceledRequest.url
        } (params: ${JSON.stringify(canceledRequest.params)})`
      );
    } else {
      return Promise.reject(error);
    }
  }
);

export const resetPending = () => {
  pending = {};
};

export { changeToken };

export default api;
