import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import routes from "router/routes";
import User from "domain/model/User/User";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { RoleType, RoleTypes } from "components/pages/StaffList";
import { Table } from "semantic-ui-react";

type Props = {
  isAdmin: boolean;
  storeId: string | undefined;
  staffs: User[] | null;
  userType: RoleType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "90%",
      display: "block",
      margin: "1rem auto 0 auto",
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
    container: {
      height: "70vh",
    },
    table1: {
      width: "20px",
    },
    table3: {
      width: "200px",
    },
    table4: {
      width: "90px",
    },
    table5: {
      width: "90px",
    },
  })
);

const StaffListTemplate: React.FC<Props> = ({
  isAdmin,
  storeId,
  staffs,
  userType,
}) => {
  const classes = useStyles();

  const staffDetailPath = generatePath(routes.admin.staffDetail.pathname, {
    storeId,
  });

  const history = useHistory();

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.table1}>ID</TableCell>
                <TableCell>名前</TableCell>
                <TableCell className={classes.table3}>メールアドレス</TableCell>
                <TableCell className={classes.table4}>権限</TableCell>
                {userType !== RoleTypes.STORESTAFF ? (
                  <TableCell className={classes.table5}>操作</TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {staffs?.map((staff: User, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{staff.id}</TableCell>
                    <TableCell>{staff.userName}</TableCell>
                    <TableCell>{staff.userEmail}</TableCell>
                    <TableCell>
                      {staff.role === "admin"
                        ? "管理者"
                        : staff.role === "store_admin"
                        ? "店舗管理者"
                        : "スタッフ"}
                    </TableCell>
                    {userType !== RoleTypes.STORESTAFF ? (
                      <TableCell>
                        {
                          <PrimaryButton
                            onClick={() =>
                              history.push(staffDetailPath, {
                                name: staff.userName,
                                email: staff.userEmail,
                                id: staff.id,
                              })
                            }
                          >
                            詳細
                          </PrimaryButton>
                        }
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default StaffListTemplate;
