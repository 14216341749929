import { UserType } from "config/menu-tool";

const setToken = (type: UserType, token: string) => {
  localStorage.removeItem("admin_token");
  localStorage.removeItem("storeAdmin_token");
  localStorage.removeItem("storeStaff_token");

  localStorage.setItem(`${type}_token`, token);
};

const getToken = (type: UserType) => localStorage.getItem(`${type}_token`);

const removeToken = () => {
  localStorage.removeItem("admin_token");
  localStorage.removeItem("storeAdmin_token");
  localStorage.removeItem("storeStaff_token");
};

const getMenu = (menu: any) => localStorage.getItem(menu);

const setMenu = (menu: any, token: string) => {
  localStorage.setItem(menu, token);
};

const removeMenu = (menu: any) => localStorage.removeItem(menu);

export { setToken, getToken, removeToken, getMenu, setMenu, removeMenu };
