import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { drinkService } from "domain/service";
import { Drink } from "domain/model/Menu";
import { DefaultTemplate } from "components/templates/DefaultTemplate";
import { StoreIdParam } from "config/menu-tool";
import { DrinkCategoryForm } from "components/templates/DrinkCategoryForm";

export type Props = {};

const Container: React.FC<Props> = () => {
  const [drinks, setDrinks] = useState<Drink[] | null>(null);

  const { storeId } = useParams<StoreIdParam>();
  const storeid = Number(storeId);

  useEffect(() => {
    const fetchDrinks = () => {
      drinkService.fetchAllDrink(storeId).then((res) => setDrinks(res));
    };
    fetchDrinks();
  }, [storeId]);

  return (
    <>
      <DrinkCategoryForm storeId={storeid} drinks={drinks} />
    </>
  );
};

const DrinkList: React.FC<Props> = () => {
  return (
    <DefaultTemplate title="ドリンク一覧">
      <Container />
    </DefaultTemplate>
  );
};

export default DrinkList;
