import {
  SignIn,
  StoreList,
  Dashboard,
  CourseList,
  CourseCreate,
  FoodList,
  DrinkList,
  ExternalAuth,
  DataSubmission,
  StaffList,
  StaffNew,
  StaffDetail,
  CourseDetail,
  MenuCreate,
  AdminInfo,
  StaffInfo,
  StoreCreate,
} from "components/pages";

interface RouteComponent {
  [key: string]: {
    [key: string]: {
      title: string;
      pathname: string;
      Component: any;
    };
  };
}

export const routes: RouteComponent = {
  common: {
    signInAdmin: {
      title: "サインイン - 管理者",
      pathname: "/users/sign_in/admin",
      Component: SignIn,
    },
    signInStoreStaff: {
      title: "サインイン - スタッフ",
      pathname: "/users/sign_in/store_staff",
      Component: SignIn,
    },
  },
  admin: {
    storeIndex: {
      title: "店舗一覧",
      pathname: "/admin/store_list/:page",
      Component: StoreList,
    },
    storeCreate: {
      title: "店舗作成",
      pathname: "/admin/store_create",
      Component: StoreCreate,
    },
    adminInfo: {
      title: "登録情報",
      pathname: "/admin/admin-info",
      Component: AdminInfo,
    },
    dashboard: {
      title: "ホーム",
      pathname: "/stores/:storeId/dashboard",
      Component: Dashboard,
    },
    courseList: {
      title: "コース一覧",
      pathname: "/stores/:storeId/upload-data/course",
      Component: CourseList,
    },
    courseCreate: {
      title: "コース作成",
      pathname: "/stores/:storeId/upload-data/course-create",
      Component: CourseCreate,
    },
    courseDetail: {
      title: "コース詳細",
      pathname: "/stores/:storeId/upload-data/course-detail",
      Component: CourseDetail,
    },
    foodList: {
      title: "料理一覧",
      pathname: "/stores/:storeId/upload-data/food",
      Component: FoodList,
    },
    foodDetail: {
      title: "料理詳細",
      pathname: "/stores/:storeId/upload-data/food-detail",
      Component: FoodList,
    },
    drinkList: {
      title: "ドリンク一覧",
      pathname: "/stores/:storeId/upload-data/drink",
      Component: DrinkList,
    },
    externalAuth: {
      title: "アカウント連携",
      pathname: "/stores/:storeId/external-auth",
      Component: ExternalAuth,
    },
    dataSubmission: {
      title: "一括データ入稿",
      pathname: "/stores/:storeId/data-submission",
      Component: DataSubmission,
    },
    staffList: {
      title: "スタッフ一覧",
      pathname: "/stores/:storeId/staffs",
      Component: StaffList,
    },
    staffNew: {
      title: "スタッフ追加",
      pathname: "/stores/:storeId/staff/new",
      Component: StaffNew,
    },
    staffDetail: {
      title: "スタッフ詳細",
      pathname: "/stores/:storeId/staff/detail",
      Component: StaffDetail,
    },
    staffInfo: {
      title: "アカウント情報",
      pathname: "/stores/:storeId/staff-info",
      Component: StaffInfo,
    },
    menuCreate: {
      title: "メニュー表作成",
      pathname: "/stores/:storeId/menu-create",
      Component: MenuCreate,
    },
  },
} as const;

export default routes;
