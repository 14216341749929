import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useParams } from "react-router";

import { DefaultTemplate } from "../templates/DefaultTemplate";
import { DataSubmitListTemplate } from "components/templates/DataSubmitListTemplate";
import { StoreIdParam } from "config/menu-tool";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

const DataSubmission = () => {
  const classes = useStyles();
  const { storeId } = useParams<StoreIdParam>();
  const storeid = Number(storeId);

  return (
    <DefaultTemplate title="一括データ入稿">
      <Card className={classes.root}>
        <CardContent>
          <DataSubmitListTemplate storeId={storeid} />
        </CardContent>
      </Card>
    </DefaultTemplate>
  );
};

export default DataSubmission;
