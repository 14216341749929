import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { FormikTextField } from "formik-material-fields";
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  FormLabel,
  Paper,
  Box,
  Snackbar,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { staffRepository } from "domain/repository";
import routes from "router/routes";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import message from "config/message.json";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";
import { SecondaryButton } from "components/atoms/buttons/SecondaryButton";
import Loader from "components/atoms/Loader";

type FormValues = {
  name: string;
  email: string;
  password: string;
  passwordDigest: string;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialValues: FormValues = {
  name: "",
  email: "",
  password: "",
  passwordDigest: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formText: {
      alignSelf: "center",
    },
    submitButton: {
      marginTop: "24px",
    },
    buttons: {
      display: "flex",
      justifyContent: "center",
    },
    title: { textAlign: "center" },
    textBottom: { verticalAlign: "bottom" },
    radioForm: { flexDirection: "row" },
    successMessage: { color: "green" },
    errorMessage: { color: "red" },
    loading: {
      width: "100vw",
      height: "100vh",
    },
  })
);

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(message.ERROR.INVALID_EMAIL)
    .required(message.ERROR.NO_INPUT),
});

type Props = { storeId: number };

const StaffNewForm: React.FC<Props> = ({ storeId }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState<boolean>(false);
  const [isCreateOk, setIsCreateOk] = useState<boolean | null>(null);

  const history = useHistory();
  const staffListPath = generatePath(routes.admin.staffList.pathname, {
    storeId,
  });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const CreateMessage = () => {
    if (isCreateOk === null) {
      return null;
    }
    if (isCreateOk) {
      return (
        <>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              スタッフを追加しました
            </Alert>
          </Snackbar>
        </>
      );
    }
    return (
      <>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            スタッフ追加に失敗しました
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setLoading(true);
          staffRepository
            .staffNew({
              storeId,
              name: values.name,
              email: values.email,
              password: values.password,
              passwordDigest: values.passwordDigest,
            })
            .then(() => {
              setLoading(false);
              setIsCreateOk(true);
              setOpen(true);
            })
            .catch((error) => {
              setIsCreateOk(false);
              setOpen(true);
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {(formik) => (
          <Form id="staffNew" onSubmit={formik.handleSubmit}>
            <Box m={3}>
              <Paper>
                <Box m={3}>
                  <Grid
                    container
                    justify="space-around"
                    direction="row"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      className={classes.formText}
                    >
                      <FormLabel component="legend" required>
                        お名前
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <FormikTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="お名前"
                        name="name"
                        autoComplete="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.errors.name}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      className={classes.formText}
                    >
                      <FormLabel component="legend" required>
                        メールアドレス
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <FormikTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="メールアドレス"
                        name="email"
                        autoComplete="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      className={classes.formText}
                    >
                      <FormLabel component="legend" required>
                        パスワード
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <FormikTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="パスワード"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.errors.password}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      className={classes.formText}
                    >
                      <FormLabel component="legend" required>
                        パスワード（確認）
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <FormikTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="passwordDigest"
                        label="パスワード（確認）"
                        type="password"
                        id="passwordDigest"
                        autoComplete="password_digest"
                        value={formik.values.passwordDigest}
                        onChange={formik.handleChange}
                        error={formik.errors.passwordDigest}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className={classes.submitButton}
                    >
                      <div className={classes.buttons}>
                        <PrimaryButton form="staffNew">追加する</PrimaryButton>
                        <SecondaryButton
                          onClick={() => history.push(staffListPath)}
                        >
                          キャンセル
                        </SecondaryButton>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Form>
        )}
      </Formik>
      {loading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <CreateMessage />
    </div>
  );
};

export default StaffNewForm;
