import api from "utils/api";
import endpoint from "utils/endpoint";

export type Store = {
  storename: string;
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
};
class StoreRepository {
  public createStore({
    storeName,
    userName,
    email,
    password,
    passwordConfirmation,
  }: {
    storeName: string;
    userName: string;
    email: string;
    password: string;
    passwordConfirmation: string;
  }) {
    const body: {
      store: Store;
    } = {
      store: {
        storename: storeName,
        username: userName,
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
    };
    return api.post(endpoint.admin.store.root(), body).catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    });
  }
}

export default new StoreRepository();
