import { useEffect, useState } from "react";
import { courseService, foodService, drinkService } from "domain/service";
import { Course, Food, Drink } from "domain/model/Menu";

// コースメニューを取得するカスタムフック
export const useCourses = ({ storeId }: { storeId: number }) => {
  const [courses, setCourses] = useState<Course[] | null>(null);

  useEffect(() => {
    const fetchCourses = () => {
      courseService.fetchAllCourse(storeId).then((res) => {
        setCourses(res);
      });
    };
    fetchCourses();
  }, [storeId]);
  return { courses };
};

// 料理メニューを取得するカスタムフック
export const useFoods = ({ storeId }: { storeId: number }) => {
  const [foods, setFoods] = useState<Food[] | null>(null);
  useEffect(() => {
    const fetchFoods = () => {
      foodService.fetchAllFood(storeId).then((res) => {
        setFoods(res);
      });
    };
    fetchFoods();
  }, [storeId]);

  return { foods };
};

// ドリンクメニューを取得するカスタムフック
export const useDrinks = ({ storeId }: { storeId: number }) => {
  const [drinks, setDrinks] = useState<Drink[] | null>(null);

  useEffect(() => {
    const fetchDrinks = () => {
      drinkService.fetchAllDrink(storeId).then((res) => {
        setDrinks(res);
      });
    };
    fetchDrinks();
  }, [storeId]);
  return { drinks };
};
