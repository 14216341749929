import { Box, Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { ReactNode, useRef } from "react";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "end",
      marginBottom: 12,
    },
  })
);

type Props = {
  children: ReactNode | ReactNode[];
};

export const PrintToPdf: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Box className={classes.root}>
        <Button variant="contained" color="primary" onClick={handlePrint}>
          保存
        </Button>
      </Box>
      <div ref={componentRef}>{children}</div>
    </div>
  );
};
