import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText, Divider } from "@material-ui/core";
import { Items } from "domain/model/Menu";

type Props = {
  items: Items | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    menuText: {
      flex: "inherit",
    },
    line: {
      flex: 1,
      backgroundImage:
        "linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)",
      backgroundPosition: "bottom",
      backgroundSize: "5px 100px",
      backgroundRepeat: "repeat-x",
    },
  })
);

const MenuSingle: React.FC<Props> = ({ items }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="menu title">
        <ListItem>
          <ListItemText primary={items?.name} className={classes.menuText} />
          <Divider className={classes.line} />
          <ListItemText primary={items?.price} className={classes.menuText} />
          <ListItemText primary="（税込）" className={classes.menuText} />
        </ListItem>
      </List>
    </div>
  );
};

export default MenuSingle;
