import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
} from "@material-ui/core";

import { SecondaryButton } from "components/atoms/buttons/SecondaryButton";
import { courseRepository, imageRepository } from "domain/repository";
import { useCourses } from "hooks/useMenu";
import { imageService } from "domain/service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: "100px",
      fontWeight: "bold",
    },
    image: {
      maxWidth: "240px",
    },
  })
);

type Props = {
  storeId: number;

  title: string;
  realPrice: string;
  description: string;
  content: string;

  min: string;
  max: string;

  start: string;
  end: string;

  image: string;

  courseListPath: string;
};

const CourseEditForm: React.FC<Props> = ({
  storeId,
  title,
  realPrice,
  description,
  content,
  min,
  max,
  start,
  end,
  image,
  courseListPath,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const courses = useCourses({ storeId });

  const [imagePath, setImagePath] = useState("");
  const [imageId, setImageId] = useState<number>(0);

  const handleDelete = () => {
    courseRepository
      .courseDelete({
        courses,
        storeId,
        title,
        realPrice,
        description,
        content,
        number: {
          min,
          max,
        },
        time: {
          start,
          end,
        },
        image,
      })
      .then(() => {
        history.push(courseListPath);
        imageRepository.imageDelete(imageId);
      });
  };
  const menuType = "course";
  const startTime = start.slice(0, 2) + ":" + start.slice(-2);
  const endTime = end.slice(0, 2) + ":" + end.slice(-2);

  useEffect(() => {
    const fetchImagePath = () => {
      imageService.fetchAllImage(String(storeId), menuType).then((res) => {
        // eslint-disable-next-line array-callback-return
        res.map((d) => {
          if (d.s3Path === image) {
            setImageId(d.id);
            setImagePath(d.url);
          }
        });
      });
    };
    fetchImagePath();
  }, [image, storeId]);

  return (
    <>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              <TableCell variant="head" className={classes.title}>
                コース名
              </TableCell>
              <TableCell>{title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.title}>
                料金
              </TableCell>
              <TableCell>{realPrice}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.title}>
                特徴
              </TableCell>
              <TableCell>{description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.title}>
                コースの内容
              </TableCell>
              <TableCell>{content}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.title}>
                注文可能人数
              </TableCell>
              <TableCell>
                {min}名から{max}名まで
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" className={classes.title}>
                注文受付時間
              </TableCell>
              <TableCell>
                {startTime}から{endTime}まで
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <img src={imagePath} alt="" className={classes.image} />
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
      <SecondaryButton onClick={() => handleDelete()}>削除</SecondaryButton>
    </>
  );
};

export default CourseEditForm;
