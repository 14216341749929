import React, { ReactNode } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

type Props = {
  children: ReactNode;
  onClick?: () => void;
  form?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newButton: {
      marginLeft: "auto",
      display: "flex",
      margin: "12px 24px 12px 24px",
    },
  })
);

export const SecondaryButton: React.VFC<Props> = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.newButton}
        type="submit"
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
};
