import React from "react";

import routes from "router/routes";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router";

type Props = {
  myAccount: () => void;
  logout: () => void;
  children: React.ReactNode | React.ReactNode[];
};

export const AsideMenu: React.FC<Props> = ({ myAccount, logout, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const accountPath = routes.admin.adminInfo.pathname;

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(accountPath)}>
          アカウント情報
        </MenuItem>
        <MenuItem onClick={logout}>ログアウト</MenuItem>
      </Menu>
    </div>
  );
};
