import React, { memo, useEffect } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { UserContainer } from "containers";
import routes from "router/routes";
import SignInForm from "components/templates/SignInForm";
import Loader from "components/atoms/Loader";

const SignIn = memo(() => {
  const userContainer = UserContainer.useContainer();
  const { currentUser, userToken, userType } = userContainer;

  const history = useHistory();
  const { path } = useRouteMatch();

  const redirectUrl = generatePath(routes.admin.storeIndex.pathname, {
    page: 1,
  });

  useEffect(() => {
    currentUser && history.push(redirectUrl);

    // eslint-disable-next-line
  }, [history, currentUser, userType]);

  if (userToken) {
    return <Loader />;
  }

  return (
    <>
      <SignInForm
        signIn={userContainer.signIn}
        userType={path.match(/admin/) ? "admin" : "storeAdmin"}
      />
    </>
  );
});

export default SignIn;
