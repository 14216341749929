import { Type, Expose } from "class-transformer";

export default class AdminUser {
  @Type(() => CurrentUser)
  @Expose({ name: "current_user" })
  currentUser: CurrentUser;
}

class CurrentUser {
  @Expose() id: number;

  @Expose({ name: "name" }) userName: string;

  @Expose({ name: "email" }) userEmail: string;
  @Expose() role: string;

  @Type(() => Store)
  @Expose()
  store: Store;
}

class Store {
  @Expose() id: number;
  @Expose() name: string;
}
