import React from "react";
import { DefaultTemplate } from "./DefaultTemplate";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Divider, Grid, Box } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import LinkIcon from "@material-ui/icons/Link";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SettingsIcon from "@material-ui/icons/Settings";

import { MenuCard } from "components/molecules/menu/MenuCard";
import { generatePath, useHistory, useParams } from "react-router";
import { StoreIdParam } from "config/menu-tool";
import { StoreContainer, UserContainer } from "containers";
import routes from "router/routes";

type Props = {
  isAdmin: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    listItem: {
      color: theme.palette.secondary.main,
      "& span, & svg": {
        fontSize: "1.5rem",
      },
    },
  })
);

const MainContent: React.FC<Props> = ({ isAdmin }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { storeId } = useParams<StoreIdParam>();
  const { currentUserData } = UserContainer.useContainer();
  const currentUserRole = currentUserData.role;

  const coursePath = generatePath(routes.admin.courseList.pathname, {
    storeId,
  });

  const foodPath = generatePath(routes.admin.foodList.pathname, { storeId });

  const drinkPath = generatePath(routes.admin.drinkList.pathname, { storeId });

  const externalAuthPath = generatePath(routes.admin.externalAuth.pathname, {
    storeId,
  });

  const dataSubmissionPath = generatePath(
    routes.admin.dataSubmission.pathname,
    { storeId }
  );

  const menuCreatePath = generatePath(routes.admin.menuCreate.pathname, {
    storeId,
  });

  const staffPath = generatePath(routes.admin.staffList.pathname, { storeId });

  const accountPath = generatePath(routes.admin.staffInfo.pathname, {
    storeId,
  });

  return (
    <Box m={4}>
      <List className={classes.root}>
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <RestaurantIcon />
          </ListItemIcon>
          <ListItemText primary="データ入稿" className={classes.listItem} />
        </ListItem>
        <Box m={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <MenuCard
                name="コース一覧"
                description="コースの作成・編集"
                link={coursePath}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MenuCard
                name="料理一覧"
                description="料理の作成・削除"
                link={foodPath}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MenuCard
                name="ドリンク一覧"
                description="メニューの作成・編集"
                link={drinkPath}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="アカウント連携" className={classes.listItem} />
        </ListItem>
        <Box m={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <MenuCard
                name="アカウント連携"
                description="外部サービスの連携を開始"
                link={externalAuthPath}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MenuCard
                name="データ一括入稿"
                description="各サイトにデータを送信"
                link={dataSubmissionPath}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="メニュー表作成" className={classes.listItem} />
        </ListItem>
        <Box m={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <MenuCard
                name="メニュー表作成"
                description="データを基に、メニュー表を作成"
                link={menuCreatePath}
              />
            </Grid>
          </Grid>
        </Box>
        {currentUserRole === "store_staff" ? (
          <>
            <Divider />
            <ListItem>
              <ListItemIcon className={classes.listItem}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="設定・管理" className={classes.listItem} />
            </ListItem>
            <Box m={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <MenuCard
                    name="アカウント情報"
                    description="お名前やEmailの確認"
                    link={accountPath}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Divider />
            <ListItem>
              <ListItemIcon className={classes.listItem}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="設定・管理" className={classes.listItem} />
            </ListItem>
            <Box m={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <MenuCard
                    name="スタッフ管理"
                    description="スタッフの追加・修正・削除"
                    link={staffPath}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <MenuCard
                    name="アカウント情報"
                    description="お名前やEmailの確認"
                    link={accountPath}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </List>
    </Box>
  );
};

export default MainContent;
