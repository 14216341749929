import React from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AdminUser from "domain/model/User/Admin";

type Props = {
  admin: AdminUser;
};

const AdminInfoTemplate: React.FC<Props> = ({ admin }) => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableRow>
            <TableCell variant="head">ご担当者名</TableCell>
            <TableCell>{admin?.currentUser?.userName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">メールアドレス</TableCell>
            <TableCell>{admin?.currentUser?.userEmail}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminInfoTemplate;
