import { CircularProgress } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      width: "100%",
      height: "100vh",
      backgroundColor: "#1a1a1a",
      opacity: 0.7,
      zIndex: 9999,
    },
    loader: {
      position: "sticky",
      top: "50%",
      left: "50%",
    },
  })
);

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
    </div>
  );
};

export default Loader;
