import React from "react";

import { UserContainer } from "containers";
import MainContent from "components/templates/MainContent";
import { DefaultTemplate } from "components/templates/DefaultTemplate";

const Dashboard = () => {
  const userContainer = UserContainer.useContainer();
  const { isAdmin } = userContainer;

  return (
    <DefaultTemplate title="ダッシュボード">
      <MainContent isAdmin={isAdmin} />
    </DefaultTemplate>
  );
};

export default Dashboard;
