import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import StoreStaffUser from "domain/model/User/StoreStaff";

type storeQuery = {
  page?: number;
  search?: string;
};

class StoreService {
  public async fetchStores({ page, search }: storeQuery) {
    return api
      .get(endpoint.admin.store.root(), { params: { page, search } })
      .then((res) => {
        const response: { stores: StoreStaffUser[]; total: number } = {
          stores: res.data.stores.map((ele: StoreStaffUser) =>
            plainToClass(StoreStaffUser, ele, {
              excludeExtraneousValues: true,
            })
          ),
          total: res.data.total,
        };

        return response;
      });
  }

  public async fetchStore(storeId: string): Promise<StoreStaffUser> {
    const url = endpoint.admin.store.show(storeId);
    return api.get(url).then((res) => {
      return plainToClass(StoreStaffUser, res.data.store, {
        excludeExtraneousValues: true,
      });
    });
  }
}
export default new StoreService();
