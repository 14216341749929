import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from "@material-ui/core";
import styled from "styled-components";

import MenuTitle from "img/menu-title.png";
import MenuCourseTitle from "img/menu-course-title.png";
import MenuMealTitle from "img/menu-meal-title.png";
import MenuDrinkTitle from "img/menu-drink-title.png";
import MenuCourse from "components/organisms/MenuCourse";
import MenuSingle from "components/organisms/MenuSingle";
import { Course, Food, Items, Drink } from "domain/model/Menu";

type Props = {
  menusData: any | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "auto",
    },
    paper: {
      width: "100%",
      height: "auto",
      border: "none",
    },
    contents: {
      marginLeft: 32,
      marginRight: 32,
    },
    titleLine: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableCell: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    titleImageContainer: {
      marginTop: 40,
      marginBottom: 40,
      textAlign: "center",
    },
    titleImage: {
      marginTop: 40,
    },
    title: {
      width: "25%",
    },
    menuTitle: {
      width: "100%",
    },
  })
);

const MenuFormat: React.FC<Props> = ({ menusData }) => {
  const classes = useStyles();

  return (
    <DivWrapper>
      <Box className={classes.root} border={0}>
        <Box className={classes.paper}>
          <header></header>
          <Box className={classes.titleImageContainer} border={0}>
            <img src={MenuTitle} alt="title" className={classes.title} />
          </Box>

          <Box className={classes.contents} border={0}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <img
                      src={MenuCourseTitle}
                      alt="course title"
                      className={classes.menuTitle}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menusData?.course?.map((course: Course, index: any) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <MenuCourse course={course} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                <TableHead>
                  <TableRow>
                    <img
                      src={MenuMealTitle}
                      alt="course title"
                      className={classes.menuTitle}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menusData?.food?.map((foods: Food) =>
                    foods?.items?.map((items: Items) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                        >
                          <MenuSingle items={items} />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>

                <TableHead>
                  <TableRow>
                    <img
                      src={MenuDrinkTitle}
                      alt="course title"
                      className={classes.menuTitle}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menusData?.drink?.map((drinks: Drink) =>
                    drinks?.items?.map((items: Items) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                        >
                          <MenuSingle items={items} />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <footer></footer>
        </Box>
      </Box>
    </DivWrapper>
  );
};

export default MenuFormat;

const DivWrapper = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  @page {
    size: letter;
    margin: 0;
  }

  @media print {
    table.paging thead td,
    table.paging tfoot td {
      height: 0.5in;
    }
  }
  table,
  tr,
  th,
  td {
    border: none;
  }

  header,
  footer {
    width: 101%;
    margin-left: -1%;
    height: 0.3in;
    background-color: #680000;
  }

  header {
    position: absolute;
    top: 0;
  }

  @media print {
    header,
    footer {
      position: fixed;
    }

    footer {
      bottom: 0;
    }
  }
`;
