import api from "utils/api";
import endpoint from "utils/endpoint";

type Course = {
  title: string;
  realPrice: string;
  description: string;
  content: string;
  number: {
    min: string;
    max: string;
  };
  time: {
    start: string;
    end: string;
  };
  image: string;
};
class CourseRepository {
  async courseCreate({
    courses,
    storeId,
    title,
    realPrice,
    description,
    content,
    number: { min, max },
    time: { start, end },
    image,
  }: { courses: any } & { storeId: number } & Course) {
    if (courses.courses !== undefined) {
      const number = {
        min,
        max,
      };
      const time = {
        start,
        end,
      };
      const course = {
        title,
        real_price: realPrice,
        description,
        content,
        number,
        time,
        image,
      };

      courses.courses.forEach(function (d: any) {
        d.real_price = d.realPrice;
        delete d.realPrice;
      });

      const body = {
        store_id: storeId,
        menu: {
          type: "course",
          data: [course, ...courses.courses],
        },
      };
      const url = endpoint.admin.menu.root();

      return api.post(url, body).catch((error) => {
        if (error.response.data.error === undefined) {
          return Promise.reject("error");
        }

        return Promise.reject(error.response.data.error);
      });
    } else {
      const number = {
        min,
        max,
      };
      const time = {
        start,
        end,
      };

      const course = {
        title,
        real_price: realPrice,
        description,
        content,
        number,
        time,
        image,
      };
      const body = {
        store_id: storeId,
        menu: {
          type: "course",
          data: [course],
        },
      };
      const url = endpoint.admin.menu.root();

      return api.post(url, body).catch((error) => {
        if (error.response.data.error === undefined) {
          return Promise.reject("error");
        }

        return Promise.reject(error.response.data.error);
      });
    }
  }

  async courseDelete({
    courses,
    storeId,
    title,
  }: { courses: any } & { storeId: number } & Course) {
    const removeCourse = courses.courses.filter((item: any) => {
      return item.title !== title;
    });

    const allCourses = removeCourse.map((course: any) => {
      course = {
        title: course.title,
        real_price: course.realPrice,
        description: course.description,
        content: course.content,
        number: course.number,
        time: course.time,
        min: course.number.min,
        max: course.number.max,
        start: course.time.start,
        end: course.time.end,
        image: course.image,
      };
      return course;
    });

    const body = {
      store_id: storeId,
      menu: {
        type: "course",
        data: allCourses,
      },
    };
    const url = endpoint.admin.menu.root();

    return api.post(url, body).catch((error) => {
      if (error.response.data.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }
}

export default new CourseRepository();
