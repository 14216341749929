import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      width: "70%",
      bottom: "0.5rem",
    },
  })
);

export const CopyRight = () => {
  const classes = useStyles();

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.root}
    >
      {"Copyright ©️"}
      <Link color="inherit" to="/">
        メニュー管理ツール
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
