import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";

import { changeToken } from "utils/api";
import { getToken, setToken, removeToken } from "utils/localStorage";

import { userService } from "domain/service";
import { CurrentUser } from "config/menu-tool";
import { UserType } from "config/menu-tool";

const UserContainer = () => {
  const [userToken, setUserToken] = useState(() => {
    const adminToken = getToken("admin");
    const storeAdminToken = getToken("storeAdmin");
    const storeStaffToken = getToken("storeStaff");

    return adminToken
      ? { type: "admin", token: adminToken }
      : adminToken
      ? { type: "storeAdmin", token: storeAdminToken }
      : storeAdminToken
      ? { type: "storeStaff", token: storeStaffToken }
      : null;
  });

  const [currentUser, setCurrentUser] = useState<CurrentUser>(null);
  const [currentUserData, setCurrentUserData] = useState<any>("");

  useEffect(() => {
    updateCurrentUser();
    // eslint-disable-next-line
  }, []);

  const updateCurrentUser = () => {
    if (!userToken) {
      return;
    }
    if (userToken.type === "admin") {
      userService
        .fetchAdminUser()
        .then((res) => {
          setCurrentUserData(res.currentUser);
          setCurrentUser({ res, "X-Authentication-Token": Location });
        })
        .catch((e) => console.log(e));
    }
  };

  const userType = userToken?.type;

  const isAdmin = userToken?.type === "admin";

  const signIn = async (email: string, password: string, type: UserType) => {
    return userService.signIn(email, password, type).then((res) => {
      const token = res.headers["x-authentication-token"];
      changeToken(token);
      setToken(type, token);
      setUserToken({ type, token });
    });
  };

  const signOut = () => {
    removeToken();
    setCurrentUser(null);
    setUserToken(null);
  };

  return {
    currentUser,
    currentUserData,
    userToken,
    userType,
    isAdmin,
    updateCurrentUser,
    signIn,
    signOut,
  };
};

export default createContainer(UserContainer);
