import React, { ReactNode } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    nested: {
      paddingLeft: theme.spacing(9),
    },
  })
);

type Props = {
  link?: any;
  onClick?: () => void;
  title: string;
  children?: ReactNode;
};

export const SecondaryMenu = ({ link, title, children }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Link to={link} className={classes.link}>
        <List component="div" disablePadding className={classes.nested}>
          <ListItem button>
            <ListItemText primary={title} />
          </ListItem>
        </List>
      </Link>
    </div>
  );
};
