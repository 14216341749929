import { plainToClass } from "class-transformer";

import api from "utils/api";
import endpoint from "utils/endpoint";
import { Drink } from "domain/model/Menu";

class DrinkService {
  public async fetchAllDrink(storeId: string | number): Promise<Drink[]> {
    return api
      .get(endpoint.admin.menu.root(), { params: { store_id: storeId } })
      .then((res) => {
        return res.data.drink?.map((d: Drink) =>
          plainToClass(Drink, d, { excludeExtraneousValues: true })
        );
      });
  }
}

export default new DrinkService();
