import { plainToClass } from "class-transformer";

import api from "utils/api";
import endpoint from "utils/endpoint";
import { Course, Food, Drink } from "domain/model/Menu";

class MenuService {
  public async fetchAllMenu(storeId: string | number): Promise<any> {
    return await api
      .get(endpoint.admin.menu.root(), { params: { store_id: storeId } })
      .then((res) => {
        return {
          course: plainToClass(Course, res.data.course, {
            excludeExtraneousValues: true,
          }),
          food: plainToClass(Food, res.data.food, {
            excludeExtraneousValues: true,
          }),
          drink: plainToClass(Drink, res.data.drink, {
            excludeExtraneousValues: true,
          }),
        };
      });
  }
}

export default new MenuService();
