import { plainToClass } from "class-transformer";

import api from "utils/api";
import endpoint from "utils/endpoint";
import User from "domain/model/User/User";

class StaffService {
  public async fetchAllStaff(storeId: string): Promise<User[]> {
    const url = endpoint.admin.store.show(storeId);
    return api.get(url).then((res) => {
      return res.data.store.users.map((d: User) =>
        plainToClass(User, d, { excludeExtraneousValues: true })
      );
    });
  }
}

export default new StaffService();
