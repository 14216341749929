import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Box } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Form, Message } from "semantic-ui-react";

import { CopyRight } from "components/molecules/CopyRight";
import routes from "router/routes";
import MESSAGE from "config/message.json";
import { UserType } from "config/menu-tool";
import { userService } from "domain/service";

type Props = {
  signIn: Function;
  userType: UserType;
};

type FormValues = {
  email: string;
  password: string;
  unAuthorized: string;
};

const initialValues: FormValues = {
  email: "",
  password: "",
  unAuthorized: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(MESSAGE.ERROR.INVALID_EMAIL)
    .required(MESSAGE.ERROR.NO_INPUT),
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInForm: React.FC<Props> = ({ signIn, userType }) => {
  const classes = useStyles();
  const isAdmin = userType === "admin";
  const history = useHistory();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            signIn(values.email, values.password, userType)
              .then(() => {
                const refirectPath = generatePath(
                  routes.admin.storeIndex.pathname,
                  { page: 1 }
                );

                // TODO: location.hrefにする必要がある
                window.location.href = refirectPath;
              })
              .catch(() => {
                actions.setFieldError(
                  "unAuthorized",
                  MESSAGE.ERROR.SIGN_IN.NO_MATCH
                );
              });
          }}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ handleChange, handleSubmit, values, errors }) => (
            <Form className={classes.form}>
              <Form.Input
                name="email"
                label="メールアドレス"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
              />
              <Form.Input
                name="password"
                label="パスワード"
                value={values.password}
                onChange={handleChange}
                type="password"
                error={errors.password}
              />
              <Form.Button
                content="サインイン"
                onClick={() => handleSubmit()}
                className={classes.submit}
              />
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}

              {errors.unAuthorized && (
                <Message negative content={errors.unAuthorized} />
              )}
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <CopyRight />
      </Box>
    </Container>
  );
};

export default SignInForm;
