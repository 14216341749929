import { DefaultTemplate } from "components/templates/DefaultTemplate";
import CourseDetailForm from "components/templates/CourseDetailForm";
import { StoreIdParam } from "config/menu-tool";
import React from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router";

import routes from "router/routes";
import { PrimaryButton } from "components/atoms/buttons/PrimaryButton";

type CourseType = {
  title?: string;
  realPrice?: string;
  description?: string;
  content?: string;
  number: {
    min?: string;
    max?: string;
  };
  time: {
    start?: string;
    end?: string;
  };
  image: string;
};

const CourseDetail: React.FC = () => {
  const { storeId } = useParams<StoreIdParam>();

  const storeid = Number(storeId);

  const location = useLocation<CourseType>();
  const history = useHistory();

  const courseListPath = generatePath(routes.admin.courseList.pathname, {
    storeId,
  });

  const {
    title,
    realPrice,
    description,
    content,
    number: { min, max },
    time: { start, end },
    image,
  } = location.state;

  return (
    <DefaultTemplate title="コース詳細">
      <PrimaryButton onClick={() => history.push(courseListPath)}>
        一覧へ
      </PrimaryButton>
      {title &&
      realPrice &&
      description &&
      content &&
      min &&
      max &&
      start &&
      end &&
      image ? (
        <CourseDetailForm
          storeId={storeid}
          title={title}
          realPrice={realPrice}
          description={description}
          content={content}
          min={min}
          max={max}
          start={start}
          end={end}
          image={image}
          courseListPath={courseListPath}
        />
      ) : (
        <p>コースが存在しません</p>
      )}
    </DefaultTemplate>
  );
};

export default CourseDetail;
